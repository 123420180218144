import urls from '@/config/urls.js'
import { createObjRestApi } from './generic'

const baseURL = urls.activities
const apiName = 'activities'

let config = {
  apiName: apiName,
  baseURL: baseURL
}

const apiStore = createObjRestApi(config)

export default apiStore
