const dateFormat = 'DD.MM.YYYY'
const timeFormat = 'HH:mm'
const dateTimeFormat = `${dateFormat} ${timeFormat}`
const backendDateFormat = 'YYYY-MM-DD'

export {
  dateFormat,
  timeFormat,
  dateTimeFormat,
  backendDateFormat
}