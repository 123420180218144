import Vue from 'vue'
import store from '@/store'
import router from '@/router'

const ignoreGeneralErrorNames = ['CKEditorError']

/**
 * On 401 errors redirects user to the login page.
 * Returns true if 401 has been handled this way, false otherwise.
 */
export function handleUnauthorized (error) {
  const currRoute = router.history.current
  // const { isNavigationFailure, NavigationFailureType } = VueRouter

  if (
    (error.response && error.response.status === 401) ||
    (
      error.reason &&
      error.reason.response &&
      error.reason.response.status === 401
    )
  ) {
    if (store.state.nav.errors.length) {
      // Another error is already being handled,
      // so we are going to ignore this 401 error.
      return true
    }
    store.commit('auth/unauthenticate')
    store.commit('nav/clearJobs')
    if (!currRoute.matched.map(obj => obj.name).includes('login')) {
      router.push({
        name: 'login',
        query: {
          next: currRoute.fullPath
        }
      }).catch((routeErr) => {
        // For now we are silencing all errors (the following doesn't work)
        // if (!isNavigationFailure(
        //   routeErr, NavigationFailureType.redirected
        // )) {
        //   Promise.reject(routeErr)
        // }
        console.log('Silenced route push error:', routeErr)
      })
    }
    return true
  }
  return false
}

/**
 * Displays error message popup to the user.
 */
function handleError (errorData) {
  store.commit('nav/appendError', errorData)
}

/**
 * Vue error handler.
 */
Vue.config.errorHandler = function (error, component, info) {
  if (!handleUnauthorized(error)) {
    console.error(error, component, info)
    handleError({
      kind: 'VUE',
      dismissible: false,
      error,
      component,
      info
    })
  }
}

/**
 * General JS error handler.
 */
window.onerror = function (message, source, lineno, colno, error) {
  if (ignoreGeneralErrorNames.includes(error.name)) {
    console.log(`Silenced ${error.name}.`)
  } else {
    handleError({
      kind: 'GENERAL',
      dismissible: true,
      message,
      source,
      lineno,
      colno,
      error
    })
  }
}

/**
 * Promise error handler.
 * event.promise contains the promise object
 * event.reason contains the reason for the rejection
 */
window.addEventListener('unhandledrejection', function (event) {
  if (!handleUnauthorized(event)) {
    handleError({
      kind: 'PROMISE',
      dismissible: true,
      event
    })
  }
  if (event.reason.response && event.reason.response.status === 401) {
    // We don't want 401 rejections to be logged to Sentry
    event.preventDefault()
    event.stopPropagation()
    event.stopImmediatePropagation()
    return false
  }
})