import urls from '@/config/urls.js'
import { createObjRestApi } from './generic'

const baseURL = urls.agreementDefs
const apiName = 'agreementDefs'

export default createObjRestApi({
  apiName: apiName,
  baseURL: baseURL
})
