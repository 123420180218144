<template>
  <component
    v-if="currInstance"
    :is="getContentComponent()"
    :instance="currInstance"
    v-bind="htmlAttrs"
    v-on="$listeners"
  >
    <slot></slot>
    <v-icon
      v-if="currInstance.notFound && !$slots['default-content']"
      color="red darken-2"
      :title="`Nie odnaleziono bloku ${codeName}`"
      large
    >
      mdi-alert-circle-outline
    </v-icon>
    <slot
      name="default-content"
      v-if="currInstance.notFound && $slots['default-content']"
    >
    </slot>
    <slot name="children">
      <block
        v-for="child in currInstance.children"
        :key="child.id"
        :instance="child"
      >
      </block>
    </slot>
  </component>
</template>

<script>
import { mapActions } from 'vuex'
import { processHtmlAttrs } from './common.js'
import ImageContent from '@/components/cms/ImageContent.vue'
import TextContent from '@/components/cms/TextContent.vue'
import HtmlContent from '@/components/cms/HtmlContent.vue'
import EmbedContent from '@/components/cms/EmbedContent.vue'
import FileContent from '@/components/cms/FileContent.vue'

export default {
  props: {
    codeName: {
      type: String,
      default: null
    },
    instance: {
      type: Object,
      default: null
    }
  },

  data: function () {
    return {
      currInstance: null,
      htmlAttrs: {}
    }
  },

  methods: {
    getContentComponent: function () {
      const contentMap = {
        Block: null,
        TextBlock: TextContent,
        ImageBlock: ImageContent,
        HTMLBlock: HtmlContent,
        EmbedBlock: EmbedContent,
        FileBlock: FileContent
      }
      const modelName = this.currInstance.model_name

      if (this.currInstance.notFound) {
        return 'div'
      }

      return modelName ? contentMap[modelName] || null : null
    },
    ...mapActions('cms', ['findOrGetBlock'])
  },

  mounted: async function () {
    if (!this.currInstance) {
      if (this.instance) {
        this.currInstance = this.instance
      } else {
        this.currInstance = await this.findOrGetBlock(this.codeName)
      }
    }
    if (this.currInstance === null) {
      this.currInstance = {
        children: [],
        notFound: true
      }
    } else {
      this.htmlAttrs = processHtmlAttrs(this.currInstance.html_attrs)
    }
  }
}
</script>
