<template>
  <validation-provider
    ref="provider"
    :name="$attrs.name || $attrs.label"
    :mode="$attrs.mode || 'eager'"
    :rules="computedRules"
    v-slot="{ errors, valid, changed }"
  >
    <div class="s-number-input-container">
      <v-text-field
        ref="input"
        v-model="innerValue"
        :error-messages="errors"
        :success="valid"
        v-bind="$attrs"
        v-on="$listeners"
        hide-details
        outlined
        color="rgba(0,0,0,.6)"
        light
        class="
          s-number-input
          font-size-input
          placeholder-lighter
          text-light-input
          border border-radius-md
          mt-2
        "
      ></v-text-field>
      <s-btn
        small
        tertiary
        class-remove="py-5 px-4"
        :disabled="$attrs.disabled"
        @click="minusOne"
        >-</s-btn
      >
      <s-btn
        small
        tertiary
        class-remove="py-5 px-4"
        :disabled="$attrs.disabled"
        @click="plusOne"
        >+</s-btn
      >
    </div>
    <field-errors
      :errors="errors"
      :backend-errors="backendErrors"
      :changed="changed"
    ></field-errors>
  </validation-provider>
</template>

<script>
import SInputMixin from '@/components/generic/SInputMixin'
import FieldErrors from './FieldErrors.vue'

export default {
  mixins: [SInputMixin],

  components: { FieldErrors },

  props: {
    minValue: {
      type: [Number, null],
      default: null
    },
    maxValue: {
      type: [Number, null],
      default: null
    }
  },

  computed: {
    computedRules () {
      let rules = []

      if (this.rules) {
        for (const rule of this.rules.split('|')) {
          rules.push(rule)
        }
      }
      if (!rules.includes('numeric')) {
        rules.push('numeric')
      }
      if (this.minValue !== null) {
        rules.push(`min_value:${this.minValue}`)
      }
      if (this.maxValue !== null) {
        rules.push(`max_value:${this.maxValue}`)
      }
      if ('required' in this.$attrs && !rules.includes('required')) {
        rules.push('required')
      }
      return rules.join('|')
    }
  },

  methods: {
    minusOne (_event) {
      const value = parseInt(this.innerValue)
      if (!isNaN(value)) {
        if (this.minValue === null || value > this.minValue) {
          this.innerValue = value - 1
        }
      } else {
        this.innerValue = this.minValue || 0
      }
      this.$refs.input.$emit('input', this.innerValue)
    },
    plusOne (_event) {
      const value = parseInt(this.innerValue)
      if (!isNaN(value)) {
        if (this.maxValue === null || value < this.maxValue) {
          this.innerValue = value + 1
        }
      } else {
        this.innerValue = this.minValue || 0
      }
      this.$refs.input.$emit('input', this.innerValue)
    }
  }
}
</script>

<style lang="sass">
</style>
