import Vue from 'vue'
import {
  default as driverHttpAxios
}
from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js'
import {
  default as driverRouterVueRouter
} from './driverAuthVueRouter.js'
import auth from '@websanova/vue-auth/dist/v2/vue-auth.esm.js'
import {
  default as driverAuthBearerRefresh
} from './driverAuthBearerRefresh.js'
import router from '@/router'
import urls from './urls'

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router: Vue.router
  },

  drivers: {
    auth: driverAuthBearerRefresh,
    http: driverHttpAxios,
    router: driverRouterVueRouter
  },

  options: {
    rolesKey: 'type',
    notFoundRedirect: {
      name: 'user-account'
    },

    loginData: {
      url: urls.authLogin,
      method: 'POST',
      redirect: '',
      fetchUser: true,
      staySignedIn: true
    },

    fetchData: {
      url: urls.usersMe,
      method: 'GET',
      enabled: true
    },

    refreshTokenName: 'refresh_token',

    refreshData: {
      url: urls.authRefresh,
      method: 'POST',
      enabled: true,
      interval: 10
    },

    logoutData: {
      success: function () {
        this.token(this.options.refreshTokenName, null)
      }
    },

    parseUserData: function (data) {
      return data || {}
    },

    authRedirect: function (transition) {
      const currRoute = router.history.current
      if (!currRoute.matched.map(obj => obj.name).includes('login')) {
        return router.resolve({
          name: 'login',
          query: {
            next: transition.fullPath
          }
        }).href
      }
    }
  }
})

export default {}