<template>
  <component :is="containerCmp" :class="{ 'hidden-sm-and-down': !isSideNav }">
    <slot name="before"></slot>

    <component :is="itemCmp" v-if="isAuthenticated" v-bind="itemAttrs">
      <v-btn v-bind="btnAttrs" link :to="{ name: 'browse' }">Baza akcji</v-btn>
    </component>

    <component :is="itemCmp" v-if="isAuthenticated" v-bind="itemAttrs">
      <v-btn v-bind="btnAttrs" link :to="{ name: 'inspirations' }"
        >Baza inspiracji</v-btn
      >
    </component>

    <component
      v-for="page in processedPages"
      v-bind:key="page.slug"
      :is="itemCmp"
      v-bind="itemAttrs"
    >
      <v-btn v-bind="btnAttrs" link :to="`/strona/${page.slug}`">{{
        page.menu_item_name
      }}</v-btn>
    </component>

    <v-divider v-if="!isSideNav" vertical class="ml-2"></v-divider>

    <component :is="itemCmp" v-if="isAuthenticated" v-bind="itemAttrs">
      <v-btn
        v-bind="btnAttrs"
        @click="logout()"
        id="logout-btn"
        color="transparent"
        >Wyloguj się</v-btn
      >
    </component>

    <component :is="itemCmp" v-if="isAuthenticated" v-bind="itemAttrs">
      <v-btn
        v-bind="btnAttrs"
        link
        :to="{ name: 'user-profile', params: { userId: user.id } }"
        id="my-profile-nav-btn"
        >Mój profil
        <s-avatar
          rounded
          size="32"
          color="white"
          class="ml-2"
          id="curr-user-avatar"
          :user="user"
        >
        </s-avatar>
      </v-btn>
    </component>

    <component :is="itemCmp" v-if="!isAuthenticated" v-bind="itemAttrs">
      <v-btn v-bind="btnAttrs" link :to="{ name: 'register' }"
        >Zarejestruj</v-btn
      >
    </component>

    <component :is="itemCmp" v-if="!isAuthenticated" v-bind="itemAttrs">
      <v-btn
        v-bind="btnAttrs"
        color="primary"
        elevation="3"
        link
        :to="{ name: 'login' }"
        id="nav-btn-login"
      >
        Zaloguj się
      </v-btn>
    </component>

    <slot name="after"></slot>
  </component>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { VToolbarItems } from 'vuetify/lib/components/VToolbar'
import { VList, VListItem } from 'vuetify/lib/components/VList'
import { VSheet } from 'vuetify/lib/components/VSheet'

export default {
  props: {
    isSideNav: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    containerCmp () {
      return this.isSideNav ? VList : VToolbarItems
    },

    itemCmp () {
      return this.isSideNav ? VListItem : VSheet
    },

    itemAttrs () {
      return this.isSideNav
        ? {
          class: 'mb-4',
          color: 'white'
        }
        : {
          class: 'd-flex align-center justify-center ml-2',
          color: 'transparent'
        }
    },

    btnAttrs () {
      return this.isSideNav
        ? {
          block: true,
          elevation: '2',
          color: 'white',
          class: 'py-5'
        }
        : {
          elevation: '0',
          color: 'transparent'
        }
    },

    processedPages () {
      let result = []
      if (this.pages) {
        for (const page of this.pages) {
          if (page.menu_item_show) {
            if (this.isAuthenticated || !page.authenticated_only) {
              result.push(page)
            }
          }
        }
      }
      return result
    },

    ...mapState('auth', ['isAuthenticated', 'user']),
    ...mapState('pages', { pages: 'objList' })
  },

  methods: {
    logout () {
      this.$store.dispatch('auth/logout')
    },

    ...mapActions('pages', { getPages: 'getObjList' })
  },

  created () {
    if (!this.pages) {
      this.getPages()
    }
  }
}
</script>

<style lang="sass">
.v-application #nav-btn-login.v-btn.v-btn--active::after
  background-color: white !important
  height: 1px

#curr-user-avatar
  border: 2px solid var(--v-grey-lighten3) !important
  z-index: 1
</style>
