<template>
  <v-dialog
    v-model="showError"
    width="500"
    persistent
    content-class="border-radius-xl"
  >
    <s-card class="ui-elements" class-remove="pa-4">
      <v-card-title class="text-h5 error lighten-5 pb-3">
        Błąd aplikacji
        <v-spacer></v-spacer>
        <v-icon large color="error">mdi-alert-circle</v-icon>
      </v-card-title>

      <v-card-text class="pt-6">
        <p class="pb-3">Ups! Coś poszło nie tak...</p>
        <p class="pb-3">
          Spróbuj ponownie. Jeśli problem będzie się powtarzał, prosimy
          o&nbsp;kontakt z administratorem serwisu:
          <a
            class="link"
            :href="
              `mailto:${supportEmail}?` +
              `subject=Problem techniczny w serwisie ${appTitle}`
            "
            v-html="supportEmail"
          ></a
          >.
        </p>
        <p v-if="demandsReload" class="error--text pb-3">
          Uwaga! Nastąpi przeładowanie aplikacji.
        </p>
        <p class="grey--text">
          Szczegóły:
          <span v-for="(error, index) in errors" :key="index"
            >{{ index + 1 }}.{{ error.kind }}
          </span>
        </p>
      </v-card-text>

      <v-card-actions>
        <s-btn
          v-if="demandsReload"
          outlined
          class="error-landing-btn mx-auto mb-2 error--text"
          style="min-width: 130px"
          @click.prevent="goToLanding"
          >Strona główna</s-btn
        >
        <s-btn
          class="mx-auto mb-2 error"
          style="min-width: 130px"
          @click="acknowledgeError"
          >OK</s-btn
        >
      </v-card-actions>
    </s-card>
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { appTitle, supportEmail } from '@/config/env'

export default {
  data () {
    return {
      appTitle,
      supportEmail
    }
  },

  computed: {
    showError: {
      get () {
        return Boolean(this.errors.length)
      }
    },
    demandsReload () {
      return this.errors.filter((item) => !item.dismissible).length
    },
    ...mapState('nav', ['errors'])
  },

  methods: {
    acknowledgeError: function () {
      if (this.demandsReload) {
        location.reload()
      } else {
        this.$store.commit('nav/clearErrors')
        this.clearJobs()
      }
    },
    goToLanding: function () {
      document.location = this.$router.resolve({ name: 'landing' }).href
    },
    ...mapActions('nav', ['clearJobs'])
  }
}
</script>

<style lang="sass">
</style>