<template>
  <v-navigation-drawer
    app
    floating
    class="elevation-5"
    v-model="isOpen"
    disable-resize-watcher
  >
    <main-nav-items isSideNav>
      <template v-slot:before>
        <v-list-item style="height: 64px">
          <block code-name="app-bar-logo" class="mb-5"></block>
        </v-list-item>
      </template>
    </main-nav-items>
  </v-navigation-drawer>
</template>

<script>
import MainNavItems from '@/components/common/MainNavItems.vue'

export default {
  components: {
    MainNavItems
  },

  watch: {
    '$vuetify.breakpoint.width': function (_newVal, _oldVal) {
      // hide the nav if it has been opened and the window has been resized
      // to at least md breakpoint.
      if (this.isOpen && this.$vuetify.breakpoint.mdAndUp) {
        this.$store.commit('nav/updateIsOpen', false)
      }
    }
  },

  computed: {
    isOpen: {
      get () {
        return this.$store.state.nav.isOpen
      },
      set (value) {
        this.$store.commit('nav/updateIsOpen', value)
      }
    }
  }
}
</script>
