import {
  apiUrl
} from '@/config/env.js'

export default {
  apiUrl: apiUrl,

  authLogin: `${apiUrl}/auth/token/`,
  authRefresh: `${apiUrl}/auth/token/refresh/`,

  users: `${apiUrl}/users/`,
  usersRegister: `${apiUrl}/users/register/`,
  usersMe: `${apiUrl}/users/me/`,

  campaigns: `${apiUrl}/campaigns/`,
  events: `${apiUrl}/events/`,
  eventForms: `${apiUrl}/event-forms/`,
  participants: `${apiUrl}/participants/`,
  beneficiaries: `${apiUrl}/beneficiaries/`,
  beneficiaryProfileTags: `${apiUrl}/beneficiary-profile-tags/`,
  beneficiaryProfiles: `${apiUrl}/beneficiary-profiles/`,
  involvementChoices: `${apiUrl}/involvement-choices/`,
  teamChoices: `${apiUrl}/team-choices/`,

  activityForms: `${apiUrl}/activity-forms/`,
  activities: `${apiUrl}/activities/`,

  cmsBlocks: `${apiUrl}/blocks/`,
  linkTiles: `${apiUrl}/link-tiles/`,
  linkTileTags: `${apiUrl}/link-tile-tags/`,
  pages: `${apiUrl}/pages/`,

  provinces: `${apiUrl}/provinces/`,
  divisionDefs: `${apiUrl}/division-defs/`,

  eventReports: `${apiUrl}/event-reports/`,
  eventReportPhotos: `${apiUrl}/event-report-photos/`,

  leaderSurveys: `${apiUrl}/leader-surveys/`,
  LSAttachments: `${apiUrl}/leader-survey-attachments/`,
  participantSurveys: `${apiUrl}/participant-surveys/`,

  budgets: `${apiUrl}/budgets/`,

  tShirtKinds: `${apiUrl}/t-shirt-kinds/`,
  participantTShirts: `${apiUrl}/participant-t-shirts/`,
  additionalTShirts: `${apiUrl}/additional-t-shirts/`,
  packageFormulas: `${apiUrl}/package-formulas/`,
  packages: `${apiUrl}/packages/`,

  insuranceOrders: `${apiUrl}/insurance-orders/`,
  agreementDefs: `${apiUrl}/agreement-defs/`,
  eventAgreements: `${apiUrl}/event-agreements/`,
  userConsents: `${apiUrl}/user-consents/`,
  UCPData: `${apiUrl}/user-consent-personal-data/`,

  diplomaPackages: `${apiUrl}/diploma-packages/`,

  likes: `${apiUrl}/likes/`,
}
