import urls from '@/config/urls.js'
import { createObjRestApi } from './generic'

const baseURL = urls.leaderSurveys
const apiName = 'leaderSurveys'

export const LSP_STATUSES = {
  SIGNED: 'sig',
  UNSIGNED: 'uns',
  ADDITIONAL: 'adt'
}

export default createObjRestApi({
  apiName: apiName,
  baseURL: baseURL
})