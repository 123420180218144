import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    user: null,
    isAuthenticated: false
  },

  mutations: {
    authenticate (state, user) {
      state.user = user
      state.isAuthenticated = true
      localStorage.setItem('user', JSON.stringify(user))
    },

    unauthenticate (state) {
      state.user = null
      state.isAuthenticated = false
      localStorage.removeItem('user')
      sessionStorage.clear()  // to drop any incorrect cached data
    },

    /**
     * Locally updates the user obj using provided data
     */
    updateUser (state, payload) {
      state.user = {
        ...state.user,
        ...payload
      }
      localStorage.setItem('user', JSON.stringify(state.user))
    }
  },

  actions: {
    initialize (context) {
      const userStr = localStorage.getItem('user')

      if (userStr) {
        context.commit('authenticate', JSON.parse(userStr))
      }
    },

    login (context, data) {
      data = data || {}

      return new Promise((resolve, reject) => {
        Vue.auth.login({
          data: data.body,
          remember: data.remember,
          staySignedIn: data.staySignedIn

        }).then(
          (response) => {
            if (data.remember) {
              Vue.auth.remember(
                JSON.stringify(context.state.user)
              )
            }
            context.commit('authenticate', Vue.auth.user())
            resolve(response)
          },
          (rejection) => {
            reject(rejection)
          }
        )
      })
    },

    logout (context) {
      return new Promise((resolve, reject) => {
        Vue.router.push(location, 'landing')
        Vue.auth.logout().then(
          (response) => {
            context.commit('unauthenticate')
            resolve(response)
          },
          reject
        )
      })
    },

    refresh (context, data) {
      return Vue.auth.refresh(data)
    },

    // register (context, data) {
    //   data = data || {}

    //   return new Promise((resolve, reject) => {
    //     Vue.auth
    //       .register({
    //         url: urls.authRegister,
    //         data: data.body,
    //         autoLogin: false
    //       })
    //       .then(
    //         (_response) => {
    //           if (data.autoLogin) {
    //             context.dispatch('login', data).then(resolve, reject)
    //           }
    //         },
    //         reject
    //       )
    //   })
    // },

    // impersonate (context, data) {
    //   Vue.auth.impersonate({
    //     url: 'auth/' + data.user.id + '/impersonate',
    //     redirect: 'user-account'
    //   })
    // },

    // unimpersonate (context) {
    //   Vue.auth.unimpersonate({
    //     redirect: 'admin-users'
    //   })
    // },
  },

  getters: {}
}