import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import DashboardPlugin from './plugins/dashboard-plugin'
import http from './plugins/http'
import config from './config'

Vue.config.productionTip = false

// plugin setup
Vue.use(DashboardPlugin)

// function windowFocused () {
//   if (store.state['auth'].isAuthenticated) {
//     store.dispatch('auth/refresh')
//   }
// }

new Vue({
  router,
  store,
  vuetify,
  http,
  config: config,

  beforeCreate () {
    this.$store.dispatch('auth/initialize')
  },

  // created () {
  //   window.addEventListener('focus', windowFocused)
  // },

  // beforeDestroy () {
  //   window.removeEventListener('focus', windowFocused)
  // },

  render: h => h(App)
}).$mount('#app')