/**
 * Global components should be registered here.
 */
import Vue from 'vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import SCard from '@/components/generic/SCard.vue'
import SBtn from '@/components/generic/SBtn.vue'
import SProgress from '@/components/generic/SProgress.vue'
import SAvatar from '@/components/generic/SAvatar.vue'
import STextField from '@/components/generic/STextField.vue'
import STextArea from '@/components/generic/STextArea.vue'
import SNumberField from '@/components/generic/SNumberField.vue'
import SRichTextField from '@/components/generic/SRichTextField.vue'
import SSelect from '@/components/generic/SSelect.vue'
import SAutocomplete from '@/components/generic/SAutocomplete.vue'
import SCheckbox from '@/components/generic/SCheckbox.vue'
import SSwitch from '@/components/generic/SSwitch.vue'
import SRadioGroup from '@/components/generic/SRadioGroup.vue'
import SFileField from '@/components/generic/SFileField.vue'
import SDateField from '@/components/generic/SDateField.vue'
import STimeField from '@/components/generic/STimeField.vue'
import SRating from '@/components/generic/SRating.vue'
import FormErrors from '@/components/generic/FormErrors.vue'
import NotFound from '@/components/generic/NotFound.vue'
import Block from '@/components/cms/Block.vue'
import LikeWidget from '@/components/likes/LikeWidget.vue'

import PortalVue from 'portal-vue'


Vue.component('SCard', SCard)
Vue.component('SBtn', SBtn)
Vue.component('SProgress', SProgress)
Vue.component('SAvatar', SAvatar)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('STextField', STextField)
Vue.component('STextArea', STextArea)
Vue.component('SNumberField', SNumberField)
Vue.component('SRichTextField', SRichTextField)
Vue.component('SSelect', SSelect)
Vue.component('SAutocomplete', SAutocomplete)
Vue.component('SCheckbox', SCheckbox)
Vue.component('SSwitch', SSwitch)
Vue.component('SRadioGroup', SRadioGroup)
Vue.component('SFileField', SFileField)
Vue.component('SDateField', SDateField)
Vue.component('STimeField', STimeField)
Vue.component('SRating', SRating)
Vue.component('FormErrors', FormErrors)
Vue.component('NotFound', NotFound)
Vue.component('Block', Block)
Vue.component('LikeWidget', LikeWidget)

Vue.use(PortalVue)

export default {}
