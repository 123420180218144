export default {
  namespaced: true,

  state: {
    isOpen: false,
    showBusy: false,
    jobs: [],
    errors: []
  },

  mutations: {
    updateIsOpen (state, value) {
      state.isOpen = value
    },
    updateShowBusy (state, value) {
      state.showBusy = value
    },
    addJob (state, { jobId, forceUnique }) {
      if (state.jobs.includes(jobId)) {
        if (forceUnique) {
          throw new Error(`Job "${jobId}" has already been started!`)
        }
      } else {
        state.jobs.push(jobId)
      }
    },
    removeJob (state, { jobId, forceUnique }) {
      if (!state.jobs.includes(jobId)) {
        if (forceUnique) {
          throw new Error(`Job "${jobId}" has not been started yet!`)
        }
      } else {
        state.jobs.splice(state.jobs.indexOf(jobId), 1)
      }
    },
    clearJobs (state) {
      state.jobs = []
    },
    appendError (state, errorData) {
      state.errors.push(errorData)
    },
    removeError (state, errorData) {
      const index = state.errors.indexOf(errorData)

      if (index > -1) {
        state.errors.splice(index, 1)
      }
    },
    clearErrors (state) {
      state.errors = []
    }
  },

  actions: {
    startJob (context, payload) {
      context.commit('addJob', payload)
      if (context.state.jobs.length && !context.state.showBusy) {
        context.commit('updateShowBusy', true)
      }
    },
    finishJob (context, payload) {
      context.commit('removeJob', payload)
      if (!context.state.jobs.length && context.state.showBusy) {
        context.commit('updateShowBusy', false)
      }
    },
    clearJobs (context) {
      if (context.state.jobs.length) {
        context.commit('clearJobs')
      }
      if (context.state.showBusy) {
        context.commit('updateShowBusy', false)
      }
    }
  }
}