export function setTitle (str) {
  document.title = `${str}`
}

export function getDynamicHeroHeight ($vuetify) {
  for (const item of [
    ['xs', 200],
    ['sm', 250],
    ['md', 300],
    ['lg', 400],
    ['xl', 450]
  ]) {
    if ($vuetify.breakpoint.name === item[0]) {
      return item[1]
    }
  }
  return 200  // default
}

/**
 * Helper function which can be used to display proper
 * Polish plural noun form based on given value
 * and 3 possible variants.
 * @param {Number} value 
 * @param {String} singularNominativ 
 * @param {String} pluralNominativ 
 * @param {String} pluralGenitive 
 * @returns {String}
 */
export function polishPlural (
  value, singularNominativ, pluralNominativ, pluralGenitive
) {
  if (value === 1) {
    return singularNominativ
  } else if (
    value % 10 >= 2 && value % 10 <= 4 && (
      value % 100 < 10 || value % 100 >= 20
    )
  ) {
    return pluralNominativ
  } else {
    return pluralGenitive
  }
}
