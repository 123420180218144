<template>
  <div class="content content--text" v-html="instance.text"></div>
</template>

<script>
export default {
  props: {
    instance: {
      type: Object,
      required: true
    }
  },
  computed: {
    htmlAttrs: function () {
      return processHtmlAttrs(this.instance.html_attrs)
    }
  }
}
</script>