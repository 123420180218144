<template>
  <validation-provider
    ref="provider"
    :name="$attrs.name || $attrs.label"
    :mode="$attrs.mode || 'eager'"
    :rules="rules"
    v-slot="{ errors, valid, changed }"
  >
    <v-radio-group
      v-model="innerValue"
      :error-messages="errors"
      :success="valid"
      :id="$attrs['data-id']"
      :class="$attrs['data-id'] ? `id-${$attrs['data-id']}` : ''"
      v-bind="$attrs"
      v-on="$listeners"
      hide-details
    >
      <v-radio
        v-for="item in items"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        :color="$attrs.color || 'success'"
        class="mr-4"
        @blur="radioBlur"
      ></v-radio>
    </v-radio-group>
    <field-errors
      :errors="errors"
      :backend-errors="backendErrors"
      :changed="changed"
    ></field-errors>
  </validation-provider>
</template>

<script>
import SInputMixin from '@/components/generic/SInputMixin'
import FieldErrors from './FieldErrors.vue'

export default {
  mixins: [
    SInputMixin
  ],

  components: { FieldErrors },

  props: {
    items: {
      type: Array,
      required: true
    }
  },

  computed: {
    itemValues: function () {
      return this.items.map(item => item.value)
    }
  },

  methods: {
    radioBlur: function (event) {
      if (
        event.relatedTarget &&
        !this.itemValues.includes(event.relatedTarget.value)
      ) {
        this.$refs.provider.validate()
      }
    }
  }
}
</script>

<style>
</style>