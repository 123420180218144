<template>
  <div>
    <div v-if="errors && errors.length">
      <p
        v-for="(error, index) in errors"
        :key="index"
        v-html="error"
        class="text-caption error--text mt-1"
      >
      </p>
    </div>
    <div v-if="backendErrors && backendErrors.length && !changed">
      <p
        v-for="(error, index) in backendErrors"
        :key="index"
        v-html="error"
        class="text-caption error--text mt-1"
      >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    errors: {
      type: [Array, null],
      default: null
    },
    backendErrors: {
      type: [Array, null],
      default: null
    },
    changed: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
</style>