<template>
  <validation-provider
    ref="provider"
    :name="$attrs.name || $attrs.label"
    :mode="$attrs.mode || 'eager'"
    :rules="rules"
    v-slot="{ errors, valid, changed }"
  >
    <v-select
      v-model="innerValue"
      :error-messages="errors"
      :success="valid"
      v-bind="$attrs"
      v-on="$listeners"
      hide-details
      outlined
      color="rgba(0,0,0,.6)"
      light
      class="
        input-style
        font-size-input
        text-light-input
        placeholder-light
        border-radius-md
        select-style
        mt-2
        mb-0
      "
      height="38"
    ></v-select>
    <field-errors
      :errors="errors"
      :backend-errors="backendErrors"
      :changed="changed"
    ></field-errors>
  </validation-provider>
</template>

<script>
import SInputMixin from '@/components/generic/SInputMixin'
import FieldErrors from './FieldErrors.vue'

export default {
  mixins: [SInputMixin],

  components: { FieldErrors }
}
</script>
