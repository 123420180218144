<template>
  <component
    :is="tagName"
    :src="instance.image"
    v-bind="htmlAttrs"
    v-on="$listeners"
  >
    <div
      v-if="instance.overlay_opacity > 0"
      class="content__img-overlay"
      :style="
        'opacity: ' +
        instance.overlay_opacity / 100 +
        '; background: ' +
        instance.overlay_color
      "
    ></div>
    <div class="content__slot content__img-slot">
      <slot></slot>
    </div>
  </component>
</template>

<script>
import { processHtmlAttrs } from './common.js'
import VImg from 'vuetify/lib/components/VImg'
import VParallax from 'vuetify/lib/components/VParallax'

export default {
  components: {
    VImg,
    VParallax
  },

  props: {
    instance: {
      type: Object,
      required: true
    }
  },

  computed: {
    htmlAttrs: function () {
      let classes = 'content content--image'
      let attrs = [...this.instance.html_attrs]
      let index = null

      for (const i of attrs.keys()) {
        if (attrs[i].name === 'class') {
          index = i
        }
      }
      if (index === null) {
        attrs.push({ id: null, name: 'class', value: classes })
      } else {
        attrs[index].value =
          this.instance.html_attrs[index].value + ' ' + classes
      }
      return processHtmlAttrs(attrs)
    },
    /**
     * Returns tag name based on component attribute of the instance
     * which should be use as component when rendering this element.
     */
    tagName: function () {
      const cmpMap = {
        himg: 'img',
        vimg: 'v-img',
        vprl: 'v-parallax'
      }
      const cmpCode = this.instance.component
      let tagName = cmpCode ? cmpMap[cmpCode] || null : null

      if (tagName === null) {
        throw Error(`No corresponding tag for code "${cmpCode}" found.`)
      }
      return tagName
    }
  }
}
</script>

<style lang="sass">
.content
  &__img-overlay, &__img-slot
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0

  &__img-overlay
    opacity: 0.8
    background-color: black

  &__img-slot
    display: flex
    flex-direction: column
    justify-content: space-between
    text-align: center

  &--image *
    color: white
</style>
