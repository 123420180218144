import Vue from 'vue'
import * as Sentry from '@sentry/vue'
import * as env from './env'
import router from '@/router'

if (env.sentryEnabled) {
  Sentry.init({
    Vue,
    dsn: env.sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: [env.appDomain, /^\//],
      }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Number.parseFloat(env.sentrySampleRate),
    logErrors: true
  })
}

export default {}
