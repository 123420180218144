import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import pl from 'vuetify/lib/locale/pl'

Vue.use(Vuetify)

// Colors and branding

const lightTheme = {
  primary: '#00975F',
  secondary: '#275F5C',
  focus: '#719C8C',
  background: '#F8F9FA',
  grey: '#9E9E9E',
  error: '#C03E3E',
  success: '#39843C'
}

if (process.env.VUE_APP_INSTANCE_NAME == 'bnp') {
  lightTheme.primary = '#00975F'
  lightTheme.secondary = '#275F5C'

} else if (process.env.VUE_APP_INSTANCE_NAME == 'orange') {
  lightTheme.primary = '#F16E00'
  lightTheme.secondary = '#595959'
  lightTheme.success = '#265C00'

} else if (process.env.VUE_APP_INSTANCE_NAME == 'demo') {
  lightTheme.primary = '#1F69B5'
  lightTheme.secondary = '#13396b'
}

export default new Vuetify({
  theme: {
    disable: false,
    options: {
      customProperties: true
    },
    themes: {
      light: lightTheme
    }
  },
  icons: {
    iconfont: 'mdi' // 'fa'
  },
  lang: {
    locales: {
      pl
    },
    current: 'pl',
  },
  rtl: false
})