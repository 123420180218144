/**
 * Returns attrs structure ready to be bound to HTML element.
 * @param {Object} raw_attrs from the backend
 * @returns {Object} processed HTML attrs ready to bind to HTML element
 */
export function processHtmlAttrs (raw_attrs) {
  let attrs = {}

  for (const attr of raw_attrs) {
    attrs[attr.name] = attr.value
  }
  return attrs
}
