<template>
  <iframe
    class="content content--embed"
    :width="instance.width || 560"
    :height="instance.height || 315"
    :src="instance.source"
    frameborder="0"
    allow="
      accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture
    "
    allowfullscreen
  ></iframe>
</template>

<script>
import { processHtmlAttrs } from './common.js'

export default {
  props: {
    instance: {
      type: Object,
      required: true
    }
  },
  computed: {
    htmlAttrs: function () {
      return processHtmlAttrs(this.instance.html_attrs)
    }
  }
}
</script>

<style lang="sass">
  .content--embed
    max-width: 100%
    max-height: 100%
</style>