<template>
  <validation-provider
    ref="provider"
    :name="$attrs.name || $attrs.label"
    :mode="$attrs.mode || 'eager'"
    :rules="rules"
    v-slot="{ errors, valid, changed }"
  >
    <v-text-field
      v-model="innerValue"
      :error-messages="errors"
      :success="valid"
      v-bind="$attrs"
      v-on="$listeners"
      hide-details
      outlined
      color="rgba(0,0,0,.6)"
      light
      class="
        font-size-input
        placeholder-lighter
        text-light-input
        border border-radius-md
        mt-2
      "
      :validate-on-blur="true"
    >
      <template v-for="(index, name) in $slots" v-slot:[name]>
        <slot :name="name" />
      </template>
    </v-text-field>
    <field-errors
      :errors="errors"
      :backend-errors="backendErrors"
      :changed="changed"
    ></field-errors>
  </validation-provider>
</template>

<script>
import SInputMixin from '@/components/generic/SInputMixin'
import FieldErrors from './FieldErrors.vue'

export default {
  mixins: [SInputMixin],

  components: { FieldErrors }
}
</script>
