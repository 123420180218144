import Vapi from 'vuex-rest-api'
import urls from '@/config/urls.js'
import store from '@/store'

const baseURL = urls.cmsBlocks
const apiName = 'cms'

const cmsStore = new Vapi({
  baseURL: baseURL,
  state: {
    blocks: {}
  }

}).get({
  action: 'getBlock',
  path: ({ codeName }) => `${baseURL}${codeName}/`,
  beforeRequest: (state, { params, data }) => {
    store.dispatch(
      'nav/startJob',
      { jobId: `${apiName}GetBlock-${params.codeName}` }
    )
  },
  onSuccess: (state, payload, axios, { params, data }) => {
    const block = payload.data.root

    state.blocks[block.code_name || block.id] = block
    store.dispatch(
      'nav/finishJob',
      { jobId: `${apiName}GetBlock-${params.codeName}` }
    )
  }

}).get({
  action: 'listBlocks',
  path: baseURL,
  queryParams: true,
  beforeRequest: (state, { params, data }) => {
    store.dispatch('nav/startJob', { jobId: `${apiName}ListBlocks` })
  },
  onSuccess: (state, payload, axios, { params, data }) => {
    for (const block of payload.data) {
      state.blocks[block.root.code_name || block.root.id] = block.root
    }
    store.dispatch('nav/finishJob', { jobId: `${apiName}ListBlocks` })
  }

}).getStore({
  namespaced: true
})

cmsStore.actions.findOrGetBlock = async ({ state, dispatch }, codeName) => {
  if (state.blocks[codeName]) {
    return state.blocks[codeName]

  } else {
    while (true) {
      try {
        await dispatch('getBlock', { params: { codeName: codeName } })
        return state.blocks[codeName]

      } catch (error) {
        store.dispatch(
          'nav/finishJob', { jobId: `${apiName}GetBlock-${codeName}` }
        )

        if (error.response.status === 404) {
          return null

        } else if (error.response.status === 401) {
          // Looks like the token has expired so let's try
          // to get the block once again as an anonymous user
          store.commit('auth/unauthenticate')

        } else {
          throw error
        }
      }
    }
  }
}

export default cmsStore