<template>
  <validation-provider
    ref="provider"
    :name="$attrs.name || $attrs.label"
    :mode="$attrs.mode || 'eager'"
    :rules="rules"
    v-slot="{ errors, valid, changed }"
  >
    <v-checkbox
      v-model="innerValue"
      :error-messages="errors"
      :success="valid"
      v-bind="$attrs"
      v-on="$listeners"
      hide-details
      color="primary"
      class="mx-0 mt-0 mb-1 checkbox-custom checkbox-thinner"
      :class="{
        required: $attrs.hasOwnProperty('required') && $attrs.required !== false
      }"
      :validate-on-blur="true"
    >
      <template #label>
        <slot></slot>
      </template>
    </v-checkbox>
    <field-errors
      :errors="errors"
      :backend-errors="backendErrors"
      :changed="changed"
    ></field-errors>
  </validation-provider>
</template>

<script>
import SInputMixin from '@/components/generic/SInputMixin'
import FieldErrors from './FieldErrors.vue'

export default {
  mixins: [SInputMixin],

  components: { FieldErrors },

  props: {
    value: {
      type: Boolean
    }
  },

  methods: {
    checkboxBlur: function (_event) {
      this.$refs.provider.validate()
    }
  },

  created () {
    if (typeof this.value === 'boolean') {
      this.innerValue = this.value
    }
  },

  mounted () {
    this.$el
      .querySelector('input[type=checkbox]')
      .addEventListener('blur', this.checkboxBlur)
  },

  beforeDestroy () {
    this.$el
      .querySelector('input[type=checkbox]')
      .removeEventListener('blur', this.checkboxBlur)
  }
}
</script>

<style>
</style>