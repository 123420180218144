<template>
  <v-app-bar app hide-on-scroll color="white" elevation="5">
    <v-toolbar-title>
      <router-link :to="{ name: 'landing' }">
        <block code-name="app-bar-logo" class="mr-5"></block>
      </router-link>
      <!--{{ appTitle }}-->
    </v-toolbar-title>

    <v-spacer></v-spacer>

    <main-nav-items></main-nav-items>

    <v-app-bar-nav-icon class="hidden-md-and-up mr-0" @click="toggleSideNav">
      <v-icon large color="black">mdi-menu</v-icon>
    </v-app-bar-nav-icon>

    <v-progress-linear
      :active="showBusy"
      :indeterminate="showBusy"
      absolute
      bottom
      color="primary"
      height="5"
      style="margin-bottom: -5px"
    ></v-progress-linear>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex'
import MainNavItems from '@/components/common/MainNavItems.vue'

export default {
  props: {
    appTitle: {
      type: String,
      required: true
    }
  },

  components: {
    MainNavItems
  },

  computed: {
    ...mapState('nav', { navIsOpen: 'isOpen', showBusy: 'showBusy' })
  },

  methods: {
    toggleSideNav () {
      this.$store.commit('nav/updateIsOpen', !this.navIsOpen)
    }
  }
}
</script>
