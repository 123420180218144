import urls from '@/config/urls.js'
import { createObjRestApi, generateOnErrorFn } from './generic'
import store from '@/store'

const baseURL = urls.eventForms
const apiName = 'eventForms'

export default createObjRestApi({
  apiName: apiName,
  baseURL: baseURL,
  state: {
    event: null
  },
  actions: [{
    method: 'post',
    action: 'createEvent',
    path: ({ id }) => `${baseURL}${id}/create_event/`,
    beforeRequest: (_state, { _params, _data }) => {
      store.dispatch('nav/startJob', { jobId: `${apiName}createEvent` })
    },
    onError: generateOnErrorFn({
      property: 'event'
    }),
    onSuccess: (state, payload, _axios, { _params, _data }) => {
      state.event = payload.data.results
      store.dispatch('nav/finishJob', { jobId: `${apiName}createEvent` })
    }
  }]
})