<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="innerValue"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <validation-provider
        ref="provider"
        :name="$attrs.name || $attrs.label"
        :mode="$attrs.mode || 'eager'"
        :rules="rules"
        v-slot="{ errors, valid, changed }"
      >
        <v-text-field
          ref="field"
          :value="dateFormatted"
          :label="$attrs.label"
          :required="$attrs.required"
          :disabled="$attrs.disabled"
          :error-messages="errors"
          :success="valid"
          v-bind="attrs"
          v-on="{ ...$listeners, ...on }"
          @keypress="onKeyPress"
          hide-details
          outlined
          color="rgba(0,0,0,.6)"
          light
          prepend-icon="mdi-calendar"
          readonly
          :class="classes"
        ></v-text-field>
        <field-errors
          :errors="errors"
          :backend-errors="backendErrors"
          :changed="changed"
        ></field-errors>
      </validation-provider>
    </template>
    <v-date-picker v-model="innerValue" :range="range" no-title scrollable>
      <v-spacer></v-spacer>
      <s-btn
        tertiary
        small
        class="calendar-cancel-btn"
        style="min-width: 86px"
        @click="menu = false"
        >Anuluj</s-btn
      >
      <s-btn
        tertiary
        small
        class="calendar-clear-btn"
        style="min-width: 86px"
        @click="clearInnerValue"
        >wyczyść</s-btn
      >
      <s-btn
        primary
        small
        class="calendar-ok-btn"
        style="min-width: 86px"
        @click="$refs.menu.save(innerValue)"
      >
        OK
      </s-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
/**
 * Warning! - passing an array to v-model prop when using with :range="true"
 * will make it not reactive!
 */
import moment from 'moment'
import { dateFormat } from '@/config/constants'
import SInputMixin from '@/components/generic/SInputMixin'
import FieldErrors from './FieldErrors.vue'

export default {
  mixins: [SInputMixin],

  components: { FieldErrors },

  data: function () {
    return {
      menu: false
    }
  },

  computed: {
    range () {
      return this.$attrs.range || false
    },

    classes () {
      let cls = [
        's-date-input',
        'font-size-input',
        'placeholder-lighter',
        'text-light-input',
        'border',
        'border-radius-md',
        'mt-2'
      ]
      if (this.$attrs.required) {
        cls.push('required')
      }
      return cls
    },

    dateFormatted () {
      let dateRange = []

      if (!(this.innerValue && this.innerValue.length)) {
        return ''
      }

      // dealing with single date
      if (!this.range) {
        return moment(this.innerValue).format(dateFormat)
      }

      // dealing with date range
      if (!typeof Array.isArray(this.innerValue)) {
        throw new Error(
          'SDateField with "range" prop should have innerValue to be an array.'
        )
      }

      // sorting dates
      dateRange = [...this.innerValue].sort((a, b) => {
        const dateA = a ? new Date(a) : null
        const dateB = b ? new Date(b) : null
        if (!dateA) return 1
        if (!dateB) return -1
        if (dateA === dateB) return 0
        return dateA < dateB ? -1 : 1
      })

      // if second date is empty
      if (!dateRange[1]) {
        return dateRange[0] ? moment(dateRange[0]).format(dateFormat) : ''
      }

      return dateRange
        .map((item) => moment(item).format(dateFormat))
        .join(' ~ ')
    }
  },

  methods: {
    onKeyPress (event) {
      if ([' ', 'Enter'].includes(event.key)) {
        this.$refs.field.$el.querySelector('input').click()
      }
    },
    clearInnerValue (_event) {
      if (this.range) {
        this.$refs.menu.save([undefined, undefined])
      } else {
        this.$refs.menu.save(null)
      }
    }
  }
}
</script>
