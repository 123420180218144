import urls from '@/config/urls.js'
import {
  createObjRestApi,
  getStatusByFactory
} from './generic'

const baseURL = urls.packages
const apiName = 'packages'

export const STATUSES = {
  PLANNED: {
    code: 'pln',
    name: 'zaplanowana',
    chipColor: 'orange darken-4',
    chipIcon: 'mdi-package-variant-closed',
    alertType: 'warning',
    descr: 'Zamówienie można zmienić do ',
    showDate: true,
    modifyDate: -1
  },
  SENT: {
    code: 'snt',
    name: 'wysłana',
    chipColor: 'green darken-3',
    chipIcon: 'mdi-package-variant-closed-check',
    alertType: 'success',
    descr: 'Twoje zamówienie zostało przekazane do magazynu ',
    showDate: true
  },
  NOT_SENT: {
    code: 'nts',
    name: 'nie wysłana',
    chipColor: 'red darken-3',
    chipIcon: 'mdi-package-variant-closed-remove',
    alertType: 'error',
    descr: 'Zamówienie nie zostało przekazane do magazynu',
    showDate: false
  },
  NO_PACKAGE: {
    code: 'nop',
    name: 'brak danych',
    chipColor: 'red darken-3',
    chipIcon: 'mdi-package-variant-closed-remove',
    alertType: 'error',
    descr: 'Wypełnij wymagane dane aby otrzymać paczkę',
    showDate: false
  }
}

export const getStatusBy = getStatusByFactory(STATUSES)

function processObjFn (obj) {
  obj.statusObj = getStatusBy('code', obj.status)
  return obj
}

export default createObjRestApi({
  apiName: apiName,
  baseURL: baseURL,
  processObjFn
})