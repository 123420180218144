import urls from '@/config/urls.js'
import { createObjRestApi } from './generic'

const baseURL = urls.likes
const apiName = 'likes'

let config = {
  apiName: apiName,
  baseURL: baseURL
}

const apiStore = createObjRestApi(config)

export default apiStore
