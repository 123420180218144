<template>
  <v-app id="app" :class="[breakpointName, 'background']">
    <side-nav></side-nav>
    <app-bar :appTitle="appTitle"></app-bar>

    <v-main>
      <v-slide-y-transition mode="out-in">
        <router-view />
      </v-slide-y-transition>
    </v-main>

    <app-footer></app-footer>

    <error-message></error-message>
  </v-app>
</template>

<script>
import AppBar from '@/components/common/AppBar.vue'
import AppFooter from '@/components/common/AppFooter.vue'
import SideNav from '@/components/common/SideNav.vue'
import ErrorMessage from '@/components/common/ErrorMessage.vue'
import { appTitle } from '@/config/env'

export default {
  name: 'App',

  components: {
    SideNav,
    AppBar,
    AppFooter,
    ErrorMessage
  },

  data: function () {
    return {
      appTitle
    }
  },

  computed: {
    breakpointName: function () {
      return `breakpoint-${this.$vuetify.breakpoint.name}`
    }
  }
}
</script>
