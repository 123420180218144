var bearerRefresh = {
  request: function (req, token) {
    if (req.url === this.options.refreshData.url) {
      req.data = {
        refresh: this.token(this.options.refreshTokenName)
      }
    }
    this.drivers.http.setHeaders.call(this, req, {
      Authorization: 'Bearer ' + token
    })
  },

  response: function (res) {
    const resData = res.data || {}

    if (resData.refresh) {
      this.token(this.options.refreshTokenName, resData.refresh)
    }
    return resData.access
  }
}

export default bearerRefresh
