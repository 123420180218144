import Vue from 'vue'
import Vuex from 'vuex'
import nav from './nav'
import auth from './auth'
import users from './users'
import campaigns from './campaigns'
import events from './events'
import eventForms from './eventForms'
import participants from './participants'
import beneficiaries from './beneficiaries'
import beneficiaryProfileTags from './beneficiaryProfileTags'
import beneficiaryProfiles from './beneficiaryProfiles'
import involvementChoices from './involvementChoices'
import teamChoices from './teamChoices'
import activityForms from './activityForms'
import activities from './activities'
import tShirtKinds from './tShirtKinds'
import participantTShirts from './participantTShirts'
import additionalTShirts from './additionalTShirts'
import packageFormulas from './packageFormulas'
import packages from './packages'
import cms from './cms'
import linkTiles from './linkTiles'
import linkTileTags from './linkTileTags'
import pages from './pages'
import provinces from './provinces'
import divisionDefs from './divisionDefs'
import eventReports from './eventReports'
import eventReportPhotos from './eventReportPhotos'
import leaderSurveys from './leaderSurveys'
import participantSurveys from './participantSurveys'
import LSAttachments from './LSAttachments'
import budgets from './budgets'
import insuranceOrders from './insuranceOrders'
import agreementDefs from './agreementDefs'
import eventAgreements from './eventAgreements'
import userConsents from './userConsents'
import UCPData from './UCPData'
import diplomaPackages from './diplomaPackages'
import likes from './likes'


Vue.use(Vuex)


export default new Vuex.Store({
  actions: {},
  modules: {
    nav,
    auth,
    users,
    campaigns,
    events,
    eventForms,
    participants,
    beneficiaries,
    beneficiaryProfiles,
    beneficiaryProfileTags,
    involvementChoices,
    teamChoices,
    activityForms,
    activities,
    tShirtKinds,
    participantTShirts,
    additionalTShirts,
    packageFormulas,
    packages,
    cms,
    linkTiles,
    linkTileTags,
    pages,
    provinces,
    divisionDefs,
    eventReports,
    eventReportPhotos,
    leaderSurveys,
    participantSurveys,
    LSAttachments,
    budgets,
    insuranceOrders,
    agreementDefs,
    eventAgreements,
    userConsents,
    UCPData,
    diplomaPackages,
    likes
  },
})