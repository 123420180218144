import urls from '@/config/urls.js'
import { createObjRestApi } from './generic'

const baseURL = urls.pages
const apiName = 'pages'

export default createObjRestApi({
  apiName: apiName,
  baseURL: baseURL
})
