import Vue from 'vue'
import Vapi from 'vuex-rest-api'
import store from '@/store'
// import { handleUnauthorized } from '@/config/errorHandlers'

export function generateOnErrorFn ({ property, isList }) {
  const onErrorFn = function (state, error, _axios, { _params, _data }) {
    // handleUnauthorized(error)
    state[property] = isList ? [] : null
  }
  return onErrorFn
}

/**
 * To avoid appending [] to the name of multi value GET parameters
 */
export function apiParamsSerializer (params) {
  let parts = []

  for (const key in params) {
    if (params.hasOwnProperty(key) && params[key] !== undefined) {
      const obj = params[key]
      if (Array.isArray(obj)) {
        for (const item of obj) {
          parts.push(key + '=' + encodeURIComponent(item))
        }
      } else {
        parts.push(key + '=' + encodeURIComponent(obj))
      }
    }
  }
  return parts.join('&')
}

export function getItemIndexById (objList, id, id_field_name) {
  if (!id_field_name) {
    id_field_name = 'id'
  }
  for (const index of objList.keys()) {
    if (objList[index][id_field_name] === id) {
      return index
    }
  }
  return null
}

/**
 * To get status by key value from map object.
  */
export function getStatusByFactory (mapObj) {
  return function (key, value) {
    if (!key) {
      throw new Error('Missing key argument!')
    }
    if (!value) {
      throw new Error('Missing value argument!')
    }
    for (const statusKey in mapObj) {
      if (mapObj[statusKey][key] === value) {
        return mapObj[statusKey]
      }
    }
    throw new Error(`Status by key "${key}" and value "${value}" not found!`)
  }
}

export function createObjRestApi ({
  apiName, baseURL, state, actions, processObjFn
}) {
  const api = new Vapi({
    baseURL: baseURL,

    state: Object.assign({
      obj: null,
      objList: null,
      listCount: null,
      listPage: null,
      listNext: null,
      listPrevious: null
    }, state)

    // default actions:

  }).get({
    action: 'getObj',
    path: ({ id }) => `${baseURL}${id}/`,
    beforeRequest: (state, { params, _data }) => {
      if (!params.dontClear) {
        state[params.objName || 'obj'] = null
      }
      store.dispatch('nav/startJob', { jobId: `${apiName}GetObj` })
    },
    onError: generateOnErrorFn({ property: 'obj' }),
    onSuccess: (state, payload, _axios, { params, _data }) => {
      const obj = processObjFn ? processObjFn(payload.data) : payload.data
      state[params.objName || 'obj'] = obj
      store.dispatch('nav/finishJob', { jobId: `${apiName}GetObj` })
    }

  }).get({
    action: 'getObjList',
    path: baseURL,
    requestConfig: {
      paramsSerializer: apiParamsSerializer
    },
    beforeRequest: (state, { params, _data }) => {
      if (!params.dontClear) {
        state.objList = null
        state.listCount = null
        state.listPage = null
        state.listNext = null
        state.listPrevious = null
      }
      store.dispatch('nav/startJob', { jobId: `${apiName}GetObjList` })
    },
    queryParams: true,
    onSuccess: (state, payload, _axios, {
      params
    }) => {
      state.objList = payload.data.results
      if (processObjFn) {
        for (let i = 0; i < state.objList.length; i++) {
          state.objList[i] = processObjFn(state.objList[i])
        }
      }
      state.listCount = payload.data.count
      state.listPage = params.page || 1
      state.listNext = payload.data.next
      state.listPrevious = payload.data.previous
      store.dispatch('nav/finishJob', { jobId: `${apiName}GetObjList` })
    },
    onError: generateOnErrorFn({property: 'objList', isList: true})

  }).post({
    action: 'postObj',
    path: baseURL,
    beforeRequest: (_state, { _params, _data }) => {
      store.dispatch('nav/startJob', { jobId: `${apiName}PostObj` })
    },
    onError: generateOnErrorFn({ property: 'postedObj' }),
    onSuccess: (state, payload, _axios, { _params, _data }) => {
      state.obj = processObjFn ? processObjFn(payload.data) : payload.data
      store.dispatch('nav/finishJob', { jobId: `${apiName}PostObj` })
    }

  }).patch({
    action: 'patchObj',
    path: ({ id }) => `${baseURL}${id}/`,
    beforeRequest: (_state, { _params, _data }) => {
      store.dispatch('nav/startJob', { jobId: `${apiName}PatchObj` })
    },
    onError: generateOnErrorFn({ property: 'patchedObj' }),
    onSuccess: (state, payload, _axios, { _params, _data }) => {
      state.obj = processObjFn ? processObjFn(payload.data) : payload.data
      store.dispatch('nav/finishJob', { jobId: `${apiName}PatchObj` })
    }

  }).delete({
    action: 'deleteObj',
    path: ({ id }) => `${baseURL}${id}/`,
    beforeRequest: (_state, { _params, _data }) => {
      store.dispatch('nav/startJob', { jobId: `${apiName}DeleteObj` })
    },
    onError: generateOnErrorFn({ property: 'deletedObj' }),
    onSuccess: (state, _payload, _axios, { _params, _data }) => {
      state.obj = null
      store.dispatch('nav/finishJob', { jobId: `${apiName}DeleteObj` })
    }
  })

  if (actions) {
    for (const action of actions) {
      api.add(action)
    }
  }

  const apiStore = api.getStore({
    namespaced: true
  })

  // Additional useful mutations and corresponding actions

  /**
   * Locally removes corresponding objName or obj from store
   */
  apiStore.mutations.removeLocalObj = (state, payload) => {
    state[payload && payload.objName || 'obj'] = null
  }

  /**
   * Locally updates the obj using provided data
   */
  apiStore.mutations.updateLocalObj = (state, payload) => {
    state.obj = {
      ...state.obj,
      ...payload
    }
  }

  /**
   * Locally appends new list item to the objList array.
   */
  apiStore.mutations.pushLocalObjListItem = (state, payload) => {
    state.objList.push(payload)
  }

  apiStore.mutations.clearLocalObjList = (state, _payload) => {
    Vue.set(state, 'objList', [])
  }

  /**
   * Locally updates objList item at given index.
   */
  apiStore.mutations.updateLocalObjListItemAtIndex = (
    state, { data, index }
  ) => {
    for (const name in data) {
      Vue.set(state.objList[index], name, data[name])
    }
  }

  apiStore.mutations.removeLocalObjListItemAtIndex = (state, index) => {
    state.objList.splice(index, 1)
  }

  apiStore.actions.updateLocalObjListItemById = (context, payload) => {
    const index = getItemIndexById(context.state.objList, payload.id)

    if (index === null) {
      throw Error(`Object with id ${payload.id} not found!`)
    } else {
      context.commit('updateLocalObjListItemAtIndex', {
        data: payload,
        index: index
      })
    }
  }

  apiStore.actions.updateLocalObj = (context, payload) => {
    context.commit('updateLocalObj', payload)
  }

  apiStore.actions.clearLocalObj = (context, _payload) => {
    context.commit('removeLocalObj')
  }

  apiStore.actions.createOrUpdateLocalObjListItem = (context, payload) => {
    const index = getItemIndexById(context.state.objList, payload.id)

    if (index === null) {
      context.commit('pushLocalObjListItem', payload)
    } else {
      context.commit('updateLocalObjListItemAtIndex', {
        data: payload,
        index: index
      })
    }
  }

  apiStore.actions.clearLocalObjList = (context, payload) => {
    context.commit('clearLocalObjList', payload)
  }

  apiStore.actions.removeLocalObjListItem = (context, { id }) => {
    const index = getItemIndexById(context.state.objList, id)

    if (index !== null) {
      context.commit('removeLocalObjListItemAtIndex', index)
    }
  }

  return apiStore
}