import urls from '@/config/urls.js'
import {
  createObjRestApi,
  getStatusByFactory
} from './generic'

const baseURL = urls.budgets
const apiName = 'budgets'

export const STATUSES = {
  NEW: {
    id: 1,
    name: 'do rozpatrzenia (nowy)',
    chipColor: 'orange darken-4',
    descr: 'Twój budżet oczekuje na rozpatrzenie przez koordynatora.',
    alertType: 'warning'
  },
  FIXED: {
    id: 2,
    name: 'do rozpatrzenia (poprawiony)',
    chipColor: 'orange darken-4',
    descr: 'Wysłano poprawki do budżetu. ' +
      'Twój zaktualizowany budżet oczekuje na akceptację koordynatora.',
    alertType: 'warning'
  },
  TO_FIX: {
    id: 3,
    name: 'do poprawy',
    chipColor: 'orange darken-4',
    descr: 'Koordynator poprosił Cię o wprowadzenie zmian w budżecie.',
    alertType: 'warning'
  },
  GRANTED: {
    id: 4,
    name: 'przyznany',
    chipColor: 'green darken-3',
    descr: 'Koordynator zaakceptował Twój budżet.',
    alertType: 'success'
  },
  REJECTED: {
    id: 5,
    name: 'odrzucony',
    chipColor: 'red darken-3',
    descr: 'Koordynator odrzucił Twój budżet.',
    alertType: 'error'
  },
  REJECTED_OK: {
    id: 6,
    name: 'odrzucony (akcja realizowana)',
    chipColor: 'orange darken-4',
    descr: 'Budżet akcji odrzucony - realizujesz akcję bez dofinansowania.',
    alertType: 'warning'
  }
}

export const getStatusBy = getStatusByFactory(STATUSES)

export default createObjRestApi({
  apiName: apiName,
  baseURL: baseURL
})