import { extend, setInteractionMode } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'

// import { messages } from 'vee-validate/dist/locale/pl.json'
import { messages } from './validationMsgPl.json'

setInteractionMode('eager')

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule], // copies rule configuration
    message: messages[rule] // assign message
  })
})

const phoneRegex =
  /(?<!\w)(\(?(\+|00)?48\)?)?[ -]?\d{3}[ -]?\d{3}[ -]?\d{3}(?!\w)/

extend('phone', value => {
  if (!value || !value.length) {
    return true
  }
  if (!phoneRegex.test(value)) {
    return 'Wprowadź poprawny numer telefonu'
  }
  return true
})

let ytRegex = new RegExp(
  '^http(s?):\\/\\/(www\\.)?youtu' +
  '(be\\.com\\/watch\\?v=|\\.be\\/|be\\.com\\/embed\\/)' +
  '([\\w\\-\\_]*)(&(amp;)?‌[\\w\\?‌​=]*)?$'
)

extend('YouTubeLink', value => {
  if (!value || !value.length) {
    return true
  }
  if (!ytRegex.test(value)) {
    return 'Wprowadź poprawny adres filmu w serwisie YouTube'
  }
  return true
})

function validatePesel (pesel) {
  const reg = /^[0-9]{11}$/

  if (reg.test(pesel) == false) {
    return false

  } else {
    const digits = ('' + pesel).split('')
    if (
      (parseInt(pesel.substring( 4, 6)) > 31) ||
      (parseInt(pesel.substring( 2, 4)) > 32)
    ) {
      return false
    }
    let checksum = (
      1 * parseInt(digits[0]) +
      3 * parseInt(digits[1]) +
      7 * parseInt(digits[2]) +
      9 * parseInt(digits[3]) +
      1 * parseInt(digits[4]) +
      3 * parseInt(digits[5]) +
      7 * parseInt(digits[6]) +
      9 * parseInt(digits[7]) +
      1 * parseInt(digits[8]) +
      3 * parseInt(digits[9])
    ) % 10
    if (checksum == 0) {
      checksum = 10
    }
    checksum = 10 - checksum

    return (parseInt(digits[10]) == checksum)
  }
}

extend('pesel', value => {
  if (!value || !value.length) {
    return true
  }
  if (!validatePesel(value)) {
    return 'Wprowadzony numer PESEL nie jest poprawny'
  }
  return true
})