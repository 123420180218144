<template>
  <v-footer padless elevation="4" class="mt-3" id="footer">
    <v-card flat tile class="flex white text-center pt-3 pb-3">
      <v-card-text class="d-flex black--text">
        <v-row>
          <v-col cols="12" id="footer-col-1">
            Pomagaj innym z pasją
            <span class="pipe-divider">|</span>
            {{ appFullTitle }}
          </v-col>
          <v-col cols="12" sm="4" id="footer-col-2">
            <block code-name="privacy-policy-file">
              <template #default-content
                ><a>Polityka bezpieczeństwa i&nbsp;prywatności</a></template
              >
            </block>
          </v-col>
          <v-col cols="12" sm="4" id="footer-col-3">
            Stworzone w
            <a href="https://heartbit-wolontariat.webflow.io" target="_blank"
              >HeartBit</a
            >
          </v-col>
          <v-col cols="12" sm="4" id="footer-col-4">
            Wsparcie techniczne:
            <a
              :href="`mailto:${supportEmail}`"
              target="_blank"
              v-html="supportEmail"
            >
            </a>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
import { appTitle, appFullTitle, supportEmail } from '@/config/env.js'

export default {
  data () {
    return {
      appTitle,
      appFullTitle,
      supportEmail
    }
  }
}
</script>

<style lang="sass">
.pipe-divider
  display: inline-block
  padding-left: 10px
  padding-right: 10px
</style>
