import urls from '@/config/urls.js'
import {
  createObjRestApi,
  getStatusByFactory
} from './generic'

const baseURL = urls.insuranceOrders
const apiName = 'insuranceOrders'

export const STATUSES = {
  PLANNED: {
    code: 'pln',
    name: 'dostępne',
    chipColor: 'orange darken-4',
    chipIcon: 'mdi-shield-edit-outline',
    alertType: 'warning',
    descr: 'Twoje zamówienie zostanie przekazane do ubezpieczyciela dnia ',
    showDate: true
  },
  SENT: {
    code: 'snt',
    name: 'wysłane',
    chipColor: 'green darken-3',
    chipIcon: 'mdi-shield-checked-outline',
    alertType: 'success',
    descr: 'Twoje zamówienie zostało przekazane do ubezpieczyciela dnia ',
    showDate: true
  },
  NOT_SENT: {
    code: 'nts',
    name: 'nie wysłane',
    chipColor: 'red darken-3',
    chipIcon: 'mdi-shield-remove-outline',
    alertType: 'error',
    descr: 'Zamówienie nie zostało przekazane do ubezpieczyciela',
    showDate: false
  }
}

export const getStatusBy = getStatusByFactory(STATUSES)

function processObjFn (obj) {
  obj.statusObj = getStatusBy('code', obj.status)
  return obj
}

export default createObjRestApi({
  apiName: apiName,
  baseURL: baseURL,
  processObjFn
})
