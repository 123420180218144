<template>
  <ValidationProvider
    ref="provider"
    :name="$attrs.name || $attrs.label"
    :mode="$attrs.mode || 'eager'"
    :rules="rules"
    v-slot="{ errors, valid, changed }"
  >
    <v-autocomplete
      v-model="innerValue"
      :items="objList || []"
      :error-messages="errors"
      :success="valid"
      :loading="isLoading"
      :search-input.sync="search"
      v-bind="$attrs"
      v-on="$listeners"
      cache-items
      hide-details
      outlined
      color="rgba(0,0,0,.6)"
      light
      class="
        input-style
        font-size-input
        text-light-input
        placeholder-light
        border-radius-md
        select-style
        mt-2
        mb-0
      "
      height="38"
    ></v-autocomplete>
    <field-errors
      :errors="errors"
      :backend-errors="backendErrors"
      :changed="changed"
    ></field-errors>
  </ValidationProvider>
</template>

<script>
import { mapActions } from 'vuex'
import SInputMixin from '@/components/generic/SInputMixin'
import FieldErrors from './FieldErrors.vue'

export default {
  mixins: [SInputMixin],

  components: { FieldErrors },

  props: {
    storeNamespace: {
      type: String,
      required: true
    },
    storeActionName: {
      type: String,
      default: 'getObjList'
    },
    storeObjListName: {
      type: String,
      default: 'objList'
    }
  },

  data () {
    return {
      isLoading: false,
      items: [],
      search: null,
      select: null
    }
  },

  computed: {
    objList: function () {
      return this.$store.state[this.storeNamespace][this.storeObjListName]
    }
  },

  watch: {
    search (val) {
      val && val !== this.select && this.querySelections(val)
    },
    innerValue (_newVal) {
      let lookup
      if (
        this.$attrs.hasOwnProperty('return-object') &&
        this.$attrs['return-object'] !== false
      ) {
        lookup = this.innerValue
          ? this.innerValue[this.$attrs.itemValue || 'id']
          : ''
      } else {
        lookup = this.innerValue
      }
      this.querySelections(lookup)
    }
  },

  methods: {
    async querySelections (val) {
      this.isLoading = true
      await this.getObjList({ params: { search: val } })
      this.isLoading = false
    },
    ...mapActions({
      getObjList (dispatch, payload) {
        return dispatch(
          `${this.storeNamespace}/${this.storeActionName}`,
          payload
        )
      }
    })
  }
}
</script>
