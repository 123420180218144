<template>
  <validation-provider
    ref="provider"
    :name="$attrs.name || $attrs.label"
    :mode="$attrs.mode || 'eager'"
    :rules="rules"
    v-slot="{ errors, valid, changed }"
  >
    <v-textarea
      v-model="innerValue"
      :error-messages="errors"
      :success="valid"
      v-bind="cmpAttrs"
      v-on="$listeners"
    ></v-textarea>
    <field-errors
      :errors="errors"
      :backend-errors="backendErrors"
      :changed="changed"
    ></field-errors>
  </validation-provider>
</template>

<script>
import SBaseMixin from '@/components/generic/SBaseMixin.js'
import SInputMixin from '@/components/generic/SInputMixin'
import FieldErrors from './FieldErrors.vue'

export default {
  mixins: [SBaseMixin, SInputMixin],

  components: { FieldErrors },

  computed: {
    defaultAttrs: function () {
      return {
        'hide-details': true,
        class: 'mt-2 s-textarea input-style font-size-input ' +
          'text-light-input placeholder-light',
        outlined: true,
        dense: true,
        flat: true,
        filled: true,
      }
    }
  }
}
</script>
