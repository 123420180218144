<template>
  <s-btn
    :class="{'like-widget': true, 'on-tile': onTile}"
    color="primary"
    class-remove="py-5 px-4"
    light
    :title="widgetTitle"
    @click="likeBtnClick"
  >
    <span class="like-counter">{{ likesCount }}</span>
    <v-icon size="18">{{
      isLiked ? 'mdi-thumb-up' : 'mdi-thumb-up-outline'
    }}</v-icon>
  </s-btn>
</template>

<script>
import { mapActions } from 'vuex'
import { polishPlural } from '@/util/common.js'

export default {
  props: {
    relObj: {
      type: Object,
      required: true
    },
    relModelName: {
      type: String,
      required: true
    },
    onTile: {
      type: Boolean,
      default: false
    },
    idFieldName: {
      type: String,
      default: 'id'
    },
    likesFieldName: {
      type: String,
      default: 'likes_count'
    },
    isLikedFieldName: {
      type: String,
      default: 'is_liked'
    }
  },

  emits: ['likeChanged'],

  computed: {
    likesCount () {
      return this.relObj[this.likesFieldName]
    },

    isLiked () {
      return this.relObj[this.isLikedFieldName]
    },

    widgetTitle () {
      let result
      if (!this.likesCount) {
        result = 'Jeszcze nikt nie polubił. Możesz być pierwszy(-sza)!'
      } else {
        result = `${this.likesCount} ${polishPlural(
          this.likesCount,
          'osoba lubi to',
          'osoby lubią to',
          'osób lubi to'
        )}${this.isLiked ? ' (łącznie z Tobą)' : ''}!`
      }
      return result
    }
  },

  methods: {
    async likeBtnClick () {
      const data = {
        rel_obj_ct: this.relModelName,
        rel_obj_id: this.relObj[this.idFieldName],
        is_liked: !this.relObj[this.isLikedFieldName]
      }
      const result = await this.postLike({ data })
      this.$emit('likeChanged', result.data)
    },

    ...mapActions('likes', { postLike: 'postObj' }),
    ...mapActions('nav', ['clearJobs'])
  }
}
</script>

<style lang="sass">
</style>