/**
 * Base mixin for form inputs.
 * Provides functionality for basic logic and validation.
 */
import Vue from 'vue'

export default {
  props: {
    rules: {
      type: [Object, String],
      default: ''
    },
    // must be included in props
    value: {
      required: true
    },
    backendErrors: {
      type: [Array, null],
      default: null
    }
  },

  data () {
    return {
      innerValue: null,
      initState: true  // input event won't be emitted in this state
    }
  },

  watch: {
    // Handles internal model changes.
    innerValue (newVal) {
      if (!this.initState) {
        this.$emit('input', newVal)
      }
    },
    // Handles external model changes.
    value (newVal) {
      this.innerValue = newVal
    },
    backendErrors (newVal) {
      if (newVal && newVal.length) {
        this.$refs.provider.setFlags({
          valid: false,
          invalid: true,
          dirty: false,
          pristine: true,
          changed: false
        })
      }
    }
  },

  created () {
    this.innerValue = this.value
    Vue.nextTick(() => {
      this.initState = false
    })
  }
}