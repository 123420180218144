import urls from '@/config/urls.js'
import { createObjRestApi } from './generic'
import { appendFeatListAction } from './campaigns'

const baseURL = urls.eventReports
const apiName = 'eventReports'

let config = {
  apiName: apiName,
  baseURL: baseURL,
  state: {
    featList: [],
    featListCount: null,
    featListPage: null,
    featListNext: null,
    featListPrevious: null
  }
}

config = appendFeatListAction(config, apiName, baseURL)

export default createObjRestApi(config)
