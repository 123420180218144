/**
 * Base mixin for skin related components.
 * Provides mechanism for propagating attributes to the underlying components.
 * Allows to extend (if String) or override defaults (name="value")
 * or explicitly override (name-override="value").
 * It's also possible to remove default value or it's part (for strings)
 * by using name-remove="value" syntax.
 */
export default {

  computed: {
    /**
     * To be used in component template by bind="cmpAttrs" syntax.
     */
    cmpAttrs: function () {
      let attrs = Object.assign({}, this.getDefaultAttrs())

      for (const name in this.$attrs) {
        if (name.endsWith('-override')) {
          attrs[
            name.replace(new RegExp('-override$'), '')
          ] = this.$attrs[name]

        } else if (name.endsWith('-remove')) {
          const attrName = name.replace(new RegExp('-remove$'), '')

          if (
            attrs[attrName] && typeof attrs[attrName] === 'string' &&
            typeof this.$attrs[name] === 'string'
          ) {
            for (const val of this.$attrs[name].split(' ')) {
              attrs[attrName] = attrs[attrName].replace(val, '')
            }
            attrs[attrName] = attrs[attrName].trim()
          } else {
            delete attrs[attrName]
          }

        } else {
          if (attrs[name] && typeof this.$attrs[name] === 'string') {
            // Append to defaults if provided as string
            attrs[name] += ` ${this.$attrs[name]}`
          } else {
            // otherwise override
            attrs[name] = this.$attrs[name]
          }
        }
      }
      return attrs
    }
  },

  methods: {
    /**
     * Can be overridden to provide dynamic default HTML attribute's values.
     */
    getDefaultAttrs: function () {
      return this.defaultAttrs || {}
    }
  }
}