<template>
  <validation-provider
    v-if="innerValue !== null"
    ref="provider"
    :name="$attrs.name || $attrs.label"
    :mode="$attrs.mode || 'eager'"
    :rules="rules"
    v-slot="{ errors, changed }"
    class="ckeditor-input"
  >
    <label class="v-label theme--light ckeditor__label" :for="$attrs.id">
      {{ $attrs.label }}
    </label>
    <ckeditor
      v-if="isReady"
      :editor="editor"
      :config="editorConfig"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="innerValue"
      tag-name="textarea"
    ></ckeditor>
    <field-errors
      :errors="errors"
      :backend-errors="backendErrors"
      :changed="changed"
    ></field-errors>
  </validation-provider>
</template>

<script>
import SInputMixin from '@/components/generic/SInputMixin'
import FieldErrors from './FieldErrors.vue'
import CKEditor from '@ckeditor/ckeditor5-vue2'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/pl'

export default {
  mixins: [SInputMixin],

  components: {
    FieldErrors,
    ckeditor: CKEditor.component
  },

  data () {
    return {
      editor: ClassicEditor,
      editorConfig: {
        language: 'pl',
        plugins: [
          'Essentials',
          'Autoformat',
          'Bold',
          'Italic',
          'Heading',
          'Indent',
          'Link',
          'List',
          'Paragraph',
          'PasteFromOffice',
          // 'Alignment'  // Currently not available in the classic build
        ],
        toolbar: [
          'heading',
          '|',
          'bold',
          'italic',
          'link',
          '|',
          // 'alignment',
          // '|',
          'bulletedList',
          'numberedList',
          'outdent',
          'indent',
          '|',
          'undo',
          'redo'
        ]
      },
      isReady: false
    }
  },

  mounted () {
    setTimeout(() => {
      // We have to delay ckeditor component rendering to avoid problems
      // with undefined value.
      this.isReady = true
    }, 100)
  }
}
</script>

<style lang="sass">
</style>