<template>
  <v-container class="text-center ui-elements">
    <h1 class="mt-6">404 - Strona nie istnieje</h1>
    <slot>
      <p>Strona o podanym adresie nie została odnaleziona</p>
    </slot>
    <p class="pt-3"><s-btn primary to="/">Przejdź na stronę startową</s-btn></p>
  </v-container>
</template>

<script>
export default {}
</script>

<style>
</style>
