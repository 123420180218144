<template>
  <component
    :is="instance.tag || 'div'"
    class="content content--html"
  >
    <div class="content__wrapper" v-html="instance.content"></div>
    <slot></slot>
  </component>
</template>

<script>
import { processHtmlAttrs } from './common.js'

export default {
  props: {
    instance: {
      type: Object,
      required: true
    }
  },
  computed: {
    htmlAttrs: function () {
      return processHtmlAttrs(this.instance.html_attrs)
    }
  }
}
</script>

<style lang="sass">
.content--html.content--stretched
  height: 100%

  .content__wrapper
    height: 100%
    display: flex
    flex-direction: column
    flex-wrap: nowrap
    justify-content: space-evenly

</style>