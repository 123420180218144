import urls from '@/config/urls.js'
import {
  createObjRestApi
} from './generic'
import {
  getStatusBy
} from './packages'

const baseURL = urls.additionalTShirts
const apiName = 'additionalTShirts'

function processObjFn (obj) {
  // We use NO_PACKAGE status when there is no package
  obj.packageStatusObj = getStatusBy(
    'code', obj.package_status || 'nop'
  )
  return obj
}

export default createObjRestApi({
  apiName: apiName,
  baseURL: baseURL,
  processObjFn
})
