<template>
  <component
    :is="tagName"
    class="content content--file"
    v-bind="computedAttrs"
    v-on="$listeners"
  >
    <span
      class="content__wrapper"
      v-if="instance.content"
      v-html="instance.content"
    ></span
    ><slot></slot
  ></component>
</template>

<script>
import VBtn from 'vuetify/lib/components/VBtn'

export default {
  components: {
    VBtn
  },

  props: {
    instance: {
      type: Object,
      required: true
    }
  },

  computed: {
    tagName: function () {
      const cmpMap = {
        anch: 'a',
        vbtn: 'v-btn',
        sbtn: 's-btn'
      }
      const cmpCode = this.instance.component
      let tagName = cmpCode ? cmpMap[cmpCode] || null : null

      if (tagName === null) {
        throw Error(`No corresponding tag for code "${cmpCode}" found.`)
      }
      return tagName
    },

    computedAttrs () {
      const attrs = {}
      if (this.instance.component === 'anch') {
        attrs.href = this.instance.file
      } else {
        attrs.to = this.instance.file
      }
      attrs.title = this.instance.tooltip || undefined
      return attrs
    }
  }
}
</script>

<style>
</style>