<template>
  <component :is="tag" v-bind="$attrs">
    <div
      v-if="
        backendErrors.non_field_errors &&
        backendErrors.non_field_errors.length &&
        !dirty
      "
    >
      <p
        v-for="(error, index) in backendErrors.non_field_errors"
        :key="index"
        v-html="error"
        class="text-caption error--text mt-1"
      ></p>
    </div>
    <p
      v-if="backendErrors.detail && !dirty"
      v-html="backendErrors.detail"
      class="text-caption error--text mt-1"
    ></p>
  </component>
</template>

<script>
export default {
  props: {
    backendErrors: {
      type: Object,
      required: true
    },
    tag: {
      type: [String, Object],
      default: 'div'
    },
    dirty: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
</style>