import Vue from 'vue'
import moment from 'moment'

import { dateFormat, timeFormat, dateTimeFormat } from './constants'

Vue.filter('formatDate', function (value) {
  let mValue
  if (!value) {
    return 'brak'
  }
  if (value instanceof moment) {
    mValue = value
  } else {
    mValue = moment(String(value))
  }
  return mValue.format(dateFormat)
})

Vue.filter('formatDateTime', function (value) {
  if (value) {
    return moment(String(value)).format(dateTimeFormat)
  }
})

Vue.filter('formatTime', function (value) {
  if (value) {
    return moment(String(value), 'HH:mm:ss').format(timeFormat)
  }
})