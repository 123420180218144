<template>
  <v-avatar v-bind="cmpAttrs">
    <!-- avatar image needs to be implemented in back-end -->
    <v-tooltip bottom :disabled="!showTooltip">
      <template v-slot:activator="{ on, attrs }">
        <router-link
          :to="{ name: 'user-profile', params: { userId: user.id } }"
        >
          <v-img
            :src="imgSrc"
            alt="Awatar"
            v-bind="attrs"
            v-on="on"
            :width="imgSize"
            :height="imgSize + 5"
          >
            <slot></slot>
          </v-img>
        </router-link>
      </template>
      <span>{{ user.full_name }}</span>
    </v-tooltip>
  </v-avatar>
</template>

<script>
import SBaseMixin from '@/components/generic/SBaseMixin.js'

export default {
  mixins: [SBaseMixin],

  props: {
    user: {
      type: Object,
      required: true
    },
    overrideSrc: {
      type: [String, null],
      default: null
    },
    showTooltip: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    defaultAttrs () {
      return {
        size: this.size,
        class: 'shadow border-radius-lg'
      }
    },
    imgSize () {
      return Number.parseInt(this.$attrs.size) || undefined
    },
    imgSrc () {
      return (
        this.overrideSrc ||
        this.user.avatar ||
        require('@/assets/img/default/avatar.png')
      )
    }
  }
}
</script>

<style>
</style>