import { createObjRestApi, generateOnErrorFn } from './generic'
import urls from '@/config/urls.js'
import store from '@/store'

const baseURL = urls.users
const apiName = 'users'

export default createObjRestApi({
  apiName: apiName,
  baseURL: baseURL,
  state: {
    activation: null,
    me: null,
    myNotifSett: null,
    passwordResetRequest: null,
    passwordResetConfirm: null,
    passwordResetValidateToken: null
  },
  actions: [{
    method: 'post',
    action: 'register',
    path: `${baseURL}register/`,
    beforeRequest: (_state, { params, data }) => {
      store.dispatch('nav/startJob', { jobId: `${apiName}Register` })
    },
    onError: generateOnErrorFn({
      property: 'obj'
    }),
    onSuccess: (state, payload, _axios, { params, data }) => {
      state.obj = payload.data
      store.dispatch('nav/finishJob', { jobId: `${apiName}Register` })
    }
  }, {
    method: 'post',
    action: 'activate',
    path: `${baseURL}activate/`,
    beforeRequest: (_state, { params, data }) => {
      store.dispatch('nav/startJob', { jobId: `${apiName}Activate` })
    },
    onError: generateOnErrorFn({
      property: 'activation'
    }),
    onSuccess: (_state, _payload, _axios, { params, data }) => {
      store.dispatch('nav/finishJob', { jobId: `${apiName}Activate` })
    }
  }, {
    method: 'get',
    action: 'getMe',
    path: `${baseURL}me/`,
    beforeRequest: (_state, { params, data }) => {
      store.dispatch('nav/startJob', { jobId: `${apiName}GetMe` })
    },
    onError: generateOnErrorFn({
      property: 'me'
    }),
    onSuccess: (state, payload, _axios, { params, data }) => {
      state.me = payload.data
      store.dispatch('nav/finishJob', { jobId: `${apiName}GetMe` })
    }
  }, {
    method: 'patch',
    action: 'updateMe',
    path: `${baseURL}me/`,
    beforeRequest: (_state, { params, data }) => {
      store.dispatch('nav/startJob', { jobId: `${apiName}UpdateMe` })
    },
    onError: generateOnErrorFn({
      property: 'meUpdateError'
    }),
    onSuccess: (state, payload, _axios, { params, data }) => {
      state.me = payload.data
      store.commit('auth/updateUser', payload.data)
      store.dispatch('nav/finishJob', { jobId: `${apiName}UpdateMe` })
    }
  }, {
    method: 'get',
    action: 'getMyNotifSett',
    path: `${baseURL}my-notification-settings/`,
    beforeRequest: (_state, { params, data }) => {
      store.dispatch(
        'nav/startJob', { jobId: `${apiName}getMyNotifSett` }
      )
    },
    onError: generateOnErrorFn({
      property: 'getMyNotifSettError'
    }),
    onSuccess: (state, payload, _axios, { params, data }) => {
      state.myNotifSett = payload.data
      store.dispatch(
        'nav/finishJob', { jobId: `${apiName}getMyNotifSett` }
      )
    }
  }, {
    method: 'patch',
    action: 'updateMyNotifSett',
    path: `${baseURL}my-notification-settings/`,
    beforeRequest: (_state, { params, data }) => {
      store.dispatch(
        'nav/startJob', { jobId: `${apiName}updateMyNotifSett` }
      )
    },
    onError: generateOnErrorFn({
      property: 'updateMyNotifSettError'
    }),
    onSuccess: (state, payload, _axios, { params, data }) => {
      state.myNotifSett = payload.data
      store.dispatch('nav/finishJob', { jobId: `${apiName}updateMyNotifSett` })
    }
  }, {
    method: 'post',
    action: 'passwordResetRequest',
    path: `${baseURL}password-reset/`,
    beforeRequest: (_state, { params, data }) => {
      store.dispatch(
        'nav/startJob', { jobId: `${apiName}PasswordResetRequest` }
      )
    },
    onError: generateOnErrorFn({
      property: 'passwordResetRequest'
    }),
    onSuccess: (_state, _payload, _axios, { params, data }) => {
      store.dispatch(
        'nav/finishJob', { jobId: `${apiName}PasswordResetRequest` }
      )
    }
  }, {
    method: 'post',
    action: 'passwordResetValidateToken',
    path: `${baseURL}password-reset/validate_token/`,
    beforeRequest: (_state, { params, data }) => {
      store.dispatch(
        'nav/startJob', { jobId: `${apiName}PasswordResetValidateToken` }
      )
    },
    onError: generateOnErrorFn({
      property: 'passwordResetValidateToken'
    }),
    onSuccess: (_state, _payload, _axios, { params, data }) => {
      store.dispatch(
        'nav/finishJob', { jobId: `${apiName}PasswordResetValidateToken` }
      )
    }
  }, {
    method: 'post',
    action: 'passwordResetConfirm',
    path: `${baseURL}password-reset/confirm/`,
    beforeRequest: (_state, { params, data }) => {
      store.dispatch(
        'nav/startJob', { jobId: `${apiName}PasswordResetConfirm` }
      )
    },
    onError: generateOnErrorFn({
      property: 'passwordResetConfirm'
    }),
    onSuccess: (_state, _payload, _axios, { params, data }) => {
      store.dispatch(
        'nav/finishJob', { jobId: `${apiName}PasswordResetConfirm` })
    }
  }]
})
