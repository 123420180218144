<template>
  <v-progress-linear v-bind="cmpAttrs" :value="valPercent">
  </v-progress-linear>
</template>

<script>
import SBaseMixin from '@/components/generic/SBaseMixin.js'

export default {
  mixins: [
    SBaseMixin
  ],

  props: {
    value: {
      type: Number,
      required: true
    },
    max: {
      type: Number,
      default: 100
    }
  },

  computed: {
    defaultAttrs: function () {
      return {}
    },
    valPercent: function () {
      return Math.round((100 * this.value) / this.max)
    }
  }
}
</script>

<style>

</style>