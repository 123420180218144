<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :nudge-right="40"
    :return-value.sync="innerValue"
    transition="scale-transition"
    offset-y
    min-width="auto"
    @input="
      (value) => value && $refs.picker && ($refs.picker.selectingHour = true)
    "
  >
    <template v-slot:activator="{ on, attrs }">
      <validation-provider
        ref="provider"
        :name="$attrs.name || $attrs.label"
        :mode="$attrs.mode || 'eager'"
        :rules="rules"
        v-slot="{ errors, valid, changed }"
      >
        <v-text-field
          ref="field"
          :value="innerValue | formatTime"
          :label="$attrs.label"
          :required="$attrs.required"
          :error-messages="errors"
          :success="valid"
          v-bind="attrs"
          v-on="{ ...$listeners, ...on }"
          @keypress="onKeyPress"
          hide-details
          outlined
          color="rgba(0,0,0,.6)"
          light
          prepend-icon="mdi-clock-time-four-outline"
          readonly
          class="
            s-date-input
            font-size-input
            placeholder-lighter
            text-light-input
            border border-radius-md
            mt-2
          "
        ></v-text-field>
        <field-errors
          :errors="errors"
          :backend-errors="backendErrors"
          :changed="changed"
        ></field-errors>
      </validation-provider>
    </template>
    <v-time-picker ref="picker" v-model="innerValue" format="24hr" scrollable>
      <v-spacer></v-spacer>
      <s-btn tertiary small @click="menu = false" style="min-width: 86px"
        >Anuluj</s-btn
      >
      <s-btn tertiary small @click="clearInnerValue" style="min-width: 86px"
        >wyczyść</s-btn
      >
      <s-btn
        primary
        small
        @click="$refs.menu.save(innerValue)"
        style="min-width: 86px"
      >
        OK
      </s-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
import SInputMixin from '@/components/generic/SInputMixin'
import FieldErrors from './FieldErrors.vue'

export default {
  mixins: [SInputMixin],

  components: { FieldErrors },

  data: function () {
    return {
      menu: false
    }
  },

  methods: {
    onKeyPress (event) {
      if ([' ', 'Enter'].includes(event.key)) {
        this.$refs.field.$el.querySelector('input').click()
      }
    },
    clearInnerValue (_event) {
      this.$refs.menu.save(null)
    }
  }
}
</script>

<style>
</style>