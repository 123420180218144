<template>
  <v-btn
    v-bind="cmpAttrs"
    :disabled="isDisabled"
    v-on:click="$emit('click', $event)"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
import { mapState } from 'vuex'
import SBaseMixin from '@/components/generic/SBaseMixin.js'

export default {
  mixins: [SBaseMixin],

  props: {
    disabledWhenBusy: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    defaultAttrs: function () {
      let attrs = {
        class: 'btn-default py-5 px-4'
      }

      for (let kind of ['primary', 'secondary', 'tertiary', 'link', 'error']) {
        if (this.$attrs.hasOwnProperty(kind)) {
          if (kind === 'link') {
            attrs.text = true
            attrs.color = 'primary'
            attrs.class = 'link pa-0'
            attrs.style = ''
            attrs.ripple = false
          } else {
            attrs.class += ` ${kind}`
          }
        }
      }
      return attrs
    },
    isDisabled: function () {
      return (this.disabledWhenBusy && this.showBusy) || this.$attrs.disabled
    },
    ...mapState('nav', ['showBusy'])
  }
}
</script>

<style lang="sass">
.v-btn.link
  height: auto !important
  text-decoration: underline
</style>