<template>
  <v-card v-bind="cmpAttrs">
    <slot></slot>
  </v-card>
</template>

<script>
import SBaseMixin from '@/components/generic/SBaseMixin.js'

export default {
  mixins: [
    SBaseMixin
  ],

  computed: {
    defaultAttrs: function () {
      return {
        class: 'card-shadow border-radius-xl pa-4'
      }
    }
  }
}
</script>

<style>

</style>