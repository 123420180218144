import urls from '@/config/urls.js'
import {
  createObjRestApi,
  generateOnErrorFn,
  apiParamsSerializer
} from './generic'
import store from '@/store'

const baseURL = urls.campaigns
const apiName = 'campaigns'

export const STATUSES = {
  PLANNED: 'PLANNED',
  ONGOING: 'ONGOING',
  ARCHIVAL: 'ARCHIVAL'
}


// *** modules ***

// warehouse module helper functions:

export function appendWarehouseMethods (obj) {
  /**
   * Should order be sent based on warehouse module settings.
   */
  obj.modules_info.warehouse.shouldSendOrder = function () {
    const warehouse = obj.modules_info.warehouse
    return Boolean(
      warehouse.is_active &&
      (warehouse.tshirts_active || warehouse.materials_active)
    )
  }

  /**
   * Returns order sending date based on event start_date and warehouse
   * modules send_before_days setting.
   */
  obj.modules_info.warehouse.getSendOrderDate = function (startDate) {
    return new Date(
      startDate.getTime() -
      obj.modules_info.warehouse.send_before_days * 24 * 60 * 60 * 1000
    )
  }

  /**
   * Returns whether is it possible to send the warehouse order
   * based on planned start date of the event and warehouse module settings.
   */
  obj.modules_info.warehouse.canSendOrder = function (startDateStr) {
    let startDate = new Date(startDateStr)
    startDate.setHours(0, 0, 0, 0)
    let today = new Date()
    today.setHours(0, 0, 0, 0)
    let sendDate = obj.modules_info.warehouse.getSendOrderDate(startDate)
    return sendDate > today
  }

  return obj
}

function processObjFn (obj) {
  if (obj.modules_info) {
    appendWarehouseMethods(obj)
  }
  return obj
}

export function appendFeatListAction (config, _apiName, _baseURL) {
  const data = {
    state: {
      featList: [],
      featListCount: null,
      featListPage: null,
      featListNext: null,
      featListPrevious: null
    },
    actions: [{
      method: 'get',
      action: 'getFeatList',
      path: `${_baseURL}?is_featured=true&is_visible=true`,
      requestConfig: {
        paramsSerializer: apiParamsSerializer
      },
      queryParams: true,
      beforeRequest: (_state, {
        _params,
        _data
      }) => {
        store.dispatch('nav/startJob', {
          jobId: `${_apiName}getFeatList`
        })
      },
      onError: generateOnErrorFn({
        property: 'featList'
      }),
      onSuccess: (state, payload, _axios, {
        params,
        _data
      }) => {
        state.featList = payload.data.results
        state.featListCount = payload.data.count
        state.featListPage = params.page || 1
        state.featListNext = payload.data.next
        state.featListPrevious = payload.data.previous
        store.dispatch('nav/finishJob', {
          jobId: `${_apiName}getFeatList`
        })
      }
    }]
  }
  config.state = config.state || {}
  config.actions = config.actions || []
  config.state = {...config.state, ...data.state}
  config.actions = [...config.actions, ...data.actions]
  config.processObjFn = config.processObjFn
  return config
}

let config = {
  apiName: apiName,
  baseURL: baseURL,
  state: {
    objMain: null
  },
  actions: [{
    method: 'get',
    action: 'getObjMain',
    path: `${baseURL}?is_main=true&is_visible=true`,
    beforeRequest: (state, { params, data }) => {
      store.dispatch('nav/startJob', {
        jobId: `${apiName}getObjMain`
      })
    },
    onError: generateOnErrorFn({
      property: 'objMain'
    }),
    onSuccess: (state, payload, _axios, { _params, _data }) => {
      state.objMain = payload.data.results.length ?
        payload.data.results[0] : null
      store.dispatch('nav/finishJob', {
        jobId: `${apiName}getObjMain`
      })
    }
  }],
  processObjFn
}

config = appendFeatListAction(config, apiName, baseURL)

const apiStore = createObjRestApi(config)

export default apiStore