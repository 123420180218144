import urls from '@/config/urls.js'
import { createObjRestApi } from './generic'
import { appendWarehouseMethods, appendFeatListAction } from './campaigns'

const baseURL = urls.events
const apiName = 'events'

function processObjFn (obj) {
  if (obj.modules_info) {
    appendWarehouseMethods(obj)
  }
  return obj
}

let config = {
  apiName: apiName,
  baseURL: baseURL,
  state: {
    featList: [],
    featListCount: null,
    featListPage: null,
    featListNext: null,
    featListPrevious: null
  },
  processObjFn
}

config = appendFeatListAction(config, apiName, baseURL)

const apiStore = createObjRestApi(config)

export default apiStore
