import urls from '@/config/urls.js'
import {
  createObjRestApi,
  getStatusByFactory
} from './generic'

const baseURL = urls.divisionDefs
const apiName = 'divisionDefs'

export const KINDS = {
  KIND_RADIO_CHOICE: {
    code: 'rad',
    name: 'lista przycisków "radio"',
    widget: 'SRadioGroup'
  },
  KIND_LIST_CHOICE: {
    code: 'lst',
    name: 'lista rozwijana',
    widget: 'SSelect'
  },
  KIND_LIST_ITEM: {
    code: 'itm',
    name: 'opcja listy',
    widget: null
  },
  KIND_INPUT_TEXT: {
    code: 'txt',
    name: 'pole tekstowe',
    widget: 'STextField'
  },
  KIND_CHECKBOX: {
    code: 'chb',
    name: 'pole wyboru',
    widget: 'DivisionToDo'
  }
}

export const getKindBy = getStatusByFactory(KINDS)

function processObjFn (origObj) {
  const obj = origObj.root || origObj

  obj.kindObj = getKindBy('code', obj.kind)

  for (let i = 0; i < obj.children.length; i++) {
    obj.children[i] = processObjFn(obj.children[i])
    if (['rad', 'lst'].includes(obj.kindObj.code)) {
      // Children will be processed to be used as list items
      obj.children[i].value = obj.children[i].id
      obj.children[i].label = obj.children[i].name
    }
  }
  return obj
}

export default createObjRestApi({
  apiName: apiName,
  baseURL: baseURL,
  processObjFn
})