import Vue from 'vue'
import VueRouter from 'vue-router'
import { appTitle } from '@/config/env'
import Store from '@/store/index.js'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'landing',
  meta: {
    title: 'Start'
  },
  component: () => import(
    '@/views/LandingPage.vue'
  )
}, {
  path: '/profil/edycja',
  name: 'my-profile-edit',
  meta: {
    title: 'Edycja mojego profilu użytkownika',
    auth: true
  },
  component: () => import(
    '@/views/MyProfileEdit.vue'
  ),
}, {
  path: '/profil/:userId',
  name: 'user-profile',
  meta: {
    title: 'Profil użytkownika',
    auth: true
  },
  component: () => import(
    '@/views/UserProfile.vue'
  ),
}, {
  path: '/quiz',
  name: 'quiz',
  meta: {
    title: 'Quiz',
    auth: true
  },
  component: () => import(
    '@/views/Quiz.vue'
  ),
}, {
  path: '/baza-akcji',
  name: 'browse',
  meta: {
    title: 'Baza akcji',
    auth: true
  },
  component: () => import(
    '@/views/EventBrowser.vue'
  ),
}, {
  path: '/kampania/:campaignId',
  name: 'campaign',
  meta: {
    title: 'Kampania',
    auth: true
  },
  component: () => import(
    '@/views/CampaignDetail.vue'
  )
}, {
  path: '/kreator-akcji/kampania/:campaignId',
  name: 'event-creator',
  meta: {
    title: 'Kreator akcji',
    auth: true
  },
  component: () => import(
    '@/views/EventCreator.vue'
  )
}, {
  path: '/kreator-aktywnosci',
  name: 'activity-creator',
  meta: {
    title: 'Kreator aktywności',
    auth: true
  },
  component: () => import(
    '@/views/ActivityCreator.vue'
  )
}, {
  path: '/akcja/:eventId',
  name: 'event',
  meta: {
    title: 'Akcja',
    auth: true
  },
  component: () => import(
    '@/views/EventDetail.vue'
  )
}, {
  path: '/akcja/:eventId/edycja',
  name: 'event-edit',
  meta: {
    title: 'Edycja akcji',
    auth: true
  },
  component: () => import(
    '@/views/EventEdit.vue'
  )
}, {
  path: '/relacja/:reportId',
  name: 'event-report',
  meta: {
    title: 'Relacja'
  },
  component: () => import(
    '@/views/EventReport.vue'
  )
}, {
  path: '/akcja/:eventId/nowa-relacja',
  name: 'event-report-add',
  meta: {
    title: 'Dodaj relację',
    auth: true
  },
  component: () => import(
    '@/views/EventReportAdd.vue'
  )
}, {
  path: '/aktywnosc/:activityId/nowa-relacja',
  name: 'activity-report-add',
  meta: {
    title: 'Dodaj relację',
    auth: true
  },
  component: () => import(
    '@/views/ActivityReportAdd.vue'
  )
}, {
  path: '/relacja/:reportId/edycja',
  name: 'event-report-edit',
  meta: {
    title: 'Edycja relacji',
    auth: true
  },
  component: () => import(
    '@/views/EventReportEdit.vue'
  )
}, {
  path: '/akcja/:eventId/nowa-ankieta-lidera',
  name: 'leader-survey-add',
  meta: {
    title: 'Wypełnij ankietę lidara',
    auth: true
  },
  component: () => import(
    '@/views/LeaderSurveyAdd.vue'
  )
}, {
  path: '/akcja/:eventId/nowa-ankieta-uczestnika',
  name: 'participant-survey-add',
  meta: {
    title: 'Wypełnij ankietę uczestnika',
    auth: true
  },
  component: () => import(
    '@/views/ParticipantSurveyAdd.vue'
  )
}, {
  path: '/baza-inspiracji',
  name: 'inspirations',
  meta: {
    title: 'Baza inspiracji',
    auth: true
  },
  component: () => import(
    '@/views/InspirationsPage.vue'
  )
}, {
  path: '/logowanie',
  name: 'login',
  meta: {
    title: 'Zaloguj się'
  },
  component: () => import(
    '@/views/Login.vue'
  )
}, {
  path: '/rejestracja',
  name: 'register',
  meta: {
    title: 'Zarejestruj się'
  },
  component: () => import(
    '@/views/Register.vue'
  )
}, {
  path: '/aktywacja/:userId/:tokenKey',
  name: 'activate',
  meta: {
    title: 'Aktywacja konta'
  },
  component: () => import(
    '@/views/Activate.vue'
  )
}, {
  path: '/reset-hasla',
  name: 'password-reset',
  meta: {
    title: 'Resetuj hasło'
  },
  component: () => import(
    '@/views/PasswordReset.vue'
  )
}, {
  path: '/ustaw-nowe-haslo/:tokenKey',
  name: 'password-set-new',
  meta: {
    title: 'Ustaw nowe hasło'
  },
  component: () => import(
    '@/views/PasswordSetNew.vue'
  )
}, {
  path: '/strona/:slug',
  meta: {
    title: 'Strona'
  },
  component: () => import(
    '@/views/Page.vue'
  )
}, {
  path: '/infinite-scroll-test',
  name: 'infinite-scroll-test',
  meta: {
    'title': 'Test listy "infinite scroll"'
  },
  component: () => import(
    '@/views/InfiniteScrollTest.vue'
  )
}, {
//   path: '/skin-test',
//   name: 'skin-test',
//   meta: {
//     title: 'Test skórki',
//   },
//   component: () => import(
//     '@/views/SkinTest.vue'
//   )
// }, {
//   path: '/ui-kit',
//   name: 'ui-kit',
//   meta: {
//     title: 'UI Kit',
//   },
//   component: () => import(
//     '@/views/UiKit.vue'
//   )
// }, {
  path: '*',
  meta: {
    title: '404 - Nie znaleziono'
  },
  component: () => import(
    '@/views/PageNotFound.vue'
  )
}]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | ${appTitle}`
  Store.dispatch('nav/startJob', {
    jobId: 'route-change'
  })
  next()
})

router.afterEach((_to, _from) => {
  Store.dispatch('nav/finishJob', {
    jobId: 'route-change'
  })
})

Vue.router = router // required by @websanova/vue-auth

export default router